/* eslint-disable */
/**
 * 投标管理
 */

import { AJAX_TYPE } from "../config";

const prefix = "bid";
const baseURL = "/web/mgr/tender/bid";

export default {
  // 报名列表
  [`${prefix}ApplyList`]: {
    url: baseURL + "/getApplyList",
    type: AJAX_TYPE.POST
  },

  // 报名列表状态个数统计
  [`${prefix}CountByStatus`]: {
    url: baseURL + "/getListCountByStatus",
    type: AJAX_TYPE.GET
  },

  // 中标列表
  [`${prefix}SuccessfulList`]: {
    url: baseURL + "/getSuccessfulList",
    type: AJAX_TYPE.POST
  },

  // 获取投标报名详情
  [`${prefix}Detail`]: {
    url: baseURL + `/getPreAuditDetail?t=${new Date().getTime()}`, // 解决商城端跳转回来后ajax缓存
    type: AJAX_TYPE.GET
  },

  // 审核（通过/不通过）、取消投标报名
  [`${prefix}Cancel`]: {
    url: baseURL + "/cancelBidTender",
    type: AJAX_TYPE.POST
  },

  // 获取标书详情
  [`${prefix}DocumentDetail`]: {
    url: baseURL + "/getBidingDocument",
    type: AJAX_TYPE.GET
  },

  // 回标文件上传
  [`${prefix}Upload`]: {
    url: baseURL + "/bidingDocumentUpload",
    type: AJAX_TYPE.POST
  },
};
