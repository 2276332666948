/* eslint-disable */
import { AJAX_TYPE } from '../config';

const prefix = 'shop_category';

const baseURL = '';
// process.env.NODE_ENV == 'development' ? '' : 'http://oms-mall.st1.idf10.com.cn';

export default {
	// 新增类目
	[`${prefix}Add`]: {
		url: baseURL + '/web/mgr/shopCategory/saveShopCategory',
		type: AJAX_TYPE.POST
	},
	// 删除类目
	[`${prefix}Delete`]: {
		url: baseURL + '/web/mgr/shopCategory/deleteShopCategory',
		type: AJAX_TYPE.POST
	},
	
	//  查询类目树
	[`${prefix}Tree`]: {
		url: baseURL + '/web/mgr/shopCategory/queryShopCategorys',
		type: AJAX_TYPE.POST
	},
	// 保存类目名称
	[`${prefix}SaveShopName`]: {
		url: baseURL + '/web/mgr/shopCategory/saveShopName',
		type: AJAX_TYPE.POST
	},

	// 查看类目下商品
	[`${prefix}QueryListForShop`]: {
		url: baseURL + '/web/mgr/shopCategory/queryListForShop',
		type: AJAX_TYPE.POST
	},
	// 添加类目下商品
	[`${prefix}ShopCategoryAddGoods`]: {
		url: baseURL + '/web/mgr/shopCategory/shopCategoryAddGoods',
		type: AJAX_TYPE.POST
	},
	// 删除类目下商品
	[`${prefix}ShopCategoryDeleteGoods`]: {
		url: baseURL + '/web/mgr/shopCategory/shopCategoryDeleteGoods',
		type: AJAX_TYPE.POST
	},
	// 类目排序
	[`${prefix}SortShopCategory`]: {
		url: baseURL + '/web/mgr/shopCategory/sortShopCategory',
		type: AJAX_TYPE.POST
	},

};
