/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 消息

const prefix = 'message';

export default {
    // 查询列表
    [`${prefix}List`]: {
        url: '/web/message/list',
        type: AJAX_TYPE.POST
    },
    // 标记消息已读
    [`${prefix}Read`]: {
        url: '/web/message/read',
        type: AJAX_TYPE.POST
    },
    // 未读消息数
    [`${prefix}UnReadCnt`]: {
        url: '/web/message/unReadCnt',
        type: AJAX_TYPE.GET
    },
    // 标记消息已读
    [`${prefix}ReadAll`]: {
        url: '/web/message/readAll',
        type: AJAX_TYPE.POST
    },


    [`${prefix}ListType`]: {
        url: '/web/message/getListByType',
        type: AJAX_TYPE.POST
    },

    [`${prefix}UnReadCntByType`]: {
        url: '/web/message/unReadCntByType',
        type: AJAX_TYPE.POST
    },


    [`${prefix}ReadAllType`]: {
        url: '/web/message/readAllByType',
        type: AJAX_TYPE.POST
    },

    [`${prefix}Del`]: {
        url: '/web/message/delete',
        type: AJAX_TYPE.POST
    }
}