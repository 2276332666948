/* eslint-disable */
import React, { Component, Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import { ajax, login, loginForUc, loginForOms } from '@/http';
import { API } from '@/http/api';
import userUtil from '@/utils/user';
import { checkActivityStatus } from '@/utils/activity';
import { getUrlParam, getRedirect_url } from '@/utils';
import storage, { STORAGE_KEYS } from '@/utils/storage';
import {
  refreshRoute
} from '@/route/config';

const  NotFound = lazy(() => import('CMPT/NotFound'));
const ErrorPage = lazy(() => import('CMPT/common/ErrorPage'));
const Supplier = lazy(() => import('CMPT/Supplier'));
const HelpCenterNew = lazy(() => import('CMPT/helpCenterNew/HelpCenterNew'));
const HelpCenter = lazy(() => import('CMPT/help/HelpCenter'));
const MessageCenterNew = lazy(() => import('CMPT/message/MessageCenterNew'));
const SearchResult = lazy(() => import('CMPT/helpCenterNew/SearchResult'));
const VideoTutorial = lazy(() => import('CMPT/helpCenterNew/VideoTutorial'));
const VideoDetail = lazy(() => import('CMPT/helpCenterNew/VideoDetail'));
const CommonProblem = lazy(() => import('CMPT/helpCenterNew/CommonProblem'));
const DeliverGoods = lazy(() => import('CMPT/DeliverGoods'));
const { mallUrl, wholeDomain, canComeInOms, hasOmsYuanQuAuth, getCookie, setCookieDomin, checkURL } = userUtil;

class App extends Component {

  constructor(props) {
    super(props);
  //   let sessionId = getCookie("xxl_sso_sessionid")
  //   let USER = JSON.parse(window.localStorage.getItem("USER"))
  //   if(!!sessionId&&sessionId!='null'&&(!USER||!USER.idCode)){
  //     ajax({api: API.userAutoLogin}).then(res=>{
  //       if(res.success&&res.data.idCode!=''){
  //         setCookieDomin('userId', res.data.idCode)
  //         setCookieDomin('companyId', res.data.companyId)
  //         window.localStorage.setItem("USER",JSON.stringify(res.data))
  //         checkActivityStatus().then(()=>{
  //           this.locationTurnTo(res.data)
  //         })
  //       }
  //     })
  //   }else{
  //     console.log('无需自动登录')
  //   }
  }


  // locationTurnTo = (res) => {
  //   const redirect_url_user = getRedirect_url() || storage.get('redirect_url');
  //   const redirect_url = null;
  //   if (redirect_url_user && redirect_url_user !== 'null') {
  //     redirect_url = checkURL(redirect_url_user)
  //   }
  //   if (redirect_url && redirect_url !== 'null') {
  //     storage.set('redirect_url', 'null')
  //     setTimeout(() => {
  //       window.location.href = redirect_url;
  //     }, 500);
  //   } else {
  //     if (canComeInOms(res)) {
  //         setTimeout(() => {
  //             refreshRoute();
  //             window.location.href = '/#/supplier';
  //             window.location.reload();
  //         }, 100);
  //         // this.props.history.replace("/");
  //     } else {
  //       // message.warning('您没有OMS系统权限')
  //       window.location.href = mallUrl;
  //     }
  //   }
  // }

  
  render() {
    return (
      <Router>
        <Suspense fallback={<Spin />} >
          <Switch>
            <Route exact path="/" render={() => <Redirect to={'/supplier'} push /> } />        
          
            <Route path="/supplier" 
              onEnter={()=>{document.title="供应商中心 - 数通十方云商城"}} 
              render={(props) => {
                  if (userUtil.isLogin()) {
                  return <Supplier {...props}/>
                }
              }}
            />
            
            <Route path="/deliverGoods/:orderCode" onEnter={()=>{document.title="供应商中心 - 数通十方云商城"; }} 
              render={(props) => {
                if (userUtil.isLogin()) {
                  return <DeliverGoods {...props}/>
                }
              }}
            />
            
            <Route exact path="/helpCenterNew" render={() => <HelpCenterNew/>} /> 
            <Route path="/messageCenterNew" render={(props) => <MessageCenterNew  {...props}/>} /> 
            <Route exact path="/help" render={() => <HelpCenter/>} /> 
            <Route path="/helpCenterNew/searchResult/:keyword" render={() => <SearchResult />} /> 
            <Route exact path="/helpCenterNew/videoTutorial" render={() => <VideoTutorial />} /> 
            <Route exact path="/helpCenterNew/videoTutorial/detail/:id" render={() => <VideoDetail />} /> 
            <Route path="/helpCenterNew/commonProblem/:id" render={() => <CommonProblem />} /> 
            <Route path="/helpCenterNew/commonProblem" render={() => <CommonProblem />} /> 
            <Route path="/403" render={() =><ErrorPage code={403}/>} />
            <Route path="/500" render={() =><ErrorPage code={500}/>} />
            <Route component={NotFound} />
          </Switch>        
        </Suspense>      
      </Router>
    );
  }
}

export default App;
