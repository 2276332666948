/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 楼栋

const prefix = 'floor';

export default {
    // 新增楼层
    [`${prefix}Add`]: {
        url: '/web/floor/add',
        type: AJAX_TYPE.POST
    },
    // 删除楼层
    [`${prefix}Delete`]: {
        url: '/web/floor/delete/:floorEncode',
        type: AJAX_TYPE.POST
    },
    // 编辑楼层
    [`${prefix}Edit`]: {
        url: '/web/floor/edit',
        type: AJAX_TYPE.POST
    },
}