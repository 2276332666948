/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';
import UcSign from '@/utils/UcSign'
const { getLocalHost, sourceType } = UcSign;
// user service

const prefix = 'user';

export default {
    // 添加用户
    [`${prefix}Add`]: {
        url: '/web/user/add',
        type: AJAX_TYPE.POST
    },
    // 修改密码
    [`${prefix}ChangePwd`]: {
        url: '/web/user/changePwd',
        type: AJAX_TYPE.POST
    },
    // 用户删除
    [`${prefix}Delete`]: {
        url: '/web/user/delete/:idCode',
        type: AJAX_TYPE.GET
    },
    // 用户详情
    [`${prefix}Detail`]: {
        url: '/web/user/detail/:idCode',
        type: AJAX_TYPE.GET
    },
    // 用户编辑
    [`${prefix}Edit`]: {
        url: '/web/user/edit',
        type: AJAX_TYPE.POST
    },
    // 修改密码获取短信验证码
    [`${prefix}GetCodeModify`]: {
        url: '/web/user/getCodeModify',
        type: AJAX_TYPE.GET
    },
    // 用户列表查询
    [`${prefix}GetList`]: {
        url: '/web/user/getList',
        type: AJAX_TYPE.POST
    },
    // app用户登录
    [`${prefix}LoginForApp`]: {
        url: '/web/user/loginForApp',
        type: AJAX_TYPE.POST
    },
    // web用户登录 (oms)
    [`${prefix}LoginForWeb`]: {
        url: '/web/user/loginForWeb',
        type: AJAX_TYPE.POST
    },
    // 用户是否首次登陆
    [`${prefix}CheckLoginUser`]: {
        url: '/web/helpcenter/checkLoginUser',
        type: AJAX_TYPE.POST
    },
    // app用户退出
    [`${prefix}LogoutApp`]: {
        url: '/web/user/logoutApp',
        type: AJAX_TYPE.GET
    },
    // 用户启用关闭:status 0 关闭 1 开启
    [`${prefix}SetStatus`]: {
        url: '/web/user/setStatus/:idCode',
        type: AJAX_TYPE.GET
    },
    //用户信息
    [`${prefix}Info`]: {
        url: '/web/user/account/info',
        type: AJAX_TYPE.GET
    },
    // 员工批量分配
    [`${prefix}ToProjects`]: {
        url: '/web/user/usersToProjects',
        type: AJAX_TYPE.POST
    },

    // web用户登录 (uc)
    [`${prefix}LoginForUcWeb`]: {
        url: getLocalHost() + '/modules/api/account/common/'+ sourceType +'/v2/login',
        type: AJAX_TYPE.POST,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // web找回密码 手机验证码 (uc)
    [`${prefix}MsgCode`]: {
        url: getLocalHost() + '/modules/api/account/common/msg/code',
        type: AJAX_TYPE.POST,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // web找回密码 验证手机验证码 (uc)
    [`${prefix}ValidMsgCode`]: {
        url: getLocalHost() + '/modules/api/account/validSmsCode4getBackPassword',
        type: AJAX_TYPE.POST,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // web找回密码 设置新密码 (uc)
    [`${prefix}SetFindPasswordNew`]: {
        url: getLocalHost() + '/modules/api/account/setPassword4getBackPassword',
        type: AJAX_TYPE.POST,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // web用户登录验证码 (uc)
    [`${prefix}LoginForUcImgcode`]: {
        url: getLocalHost() + '/modules/api/account/common/imgcode',
        type: AJAX_TYPE.GET,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // web找回密码（包括短信验证码） (uc)
    [`${prefix}GetBackPassword`]: {
        url: getLocalHost() + '/modules/api/account/getBackPassword',
        type: AJAX_TYPE.POST,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // web退出登录 (uc)
    [`${prefix}Logout`]: {
        url: getLocalHost() + '/modules/api/account/common/logout',
        type: AJAX_TYPE.GET,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // =========================  修改密码 =======================
    // web 修改密码 发送短信验证码
    [`${prefix}ResetPassword`]: {
        url: getLocalHost() + '/modules/api/account/common/modify/password',
        type: AJAX_TYPE.POST,
        xhrFields: {
　　　　　　withCredentials: true
　　　　 },
    },
    // =============================================================

    [`${prefix}SkinSet`]: {
        url: '/web/crm/skin/set',
        type: AJAX_TYPE.POST
    },
    // ======================== 单点登录 ===============================
    // web用户单点登录 (oms)
    [`${prefix}UnifiedLogin`]: {
        url: '/web/crm/login/unifiedLogin',
        type: AJAX_TYPE.POST
    },
    // web用户注册 (oms)
    [`${prefix}Regiest`]: {
        url: '/web/crm/user/regiest',
        type: AJAX_TYPE.POST
    },
    // web用户注册获取手机验证码 (oms)
    [`${prefix}GetCodeRegiest`]: {
        url: '/web/crm/user/getCodeRegiest',
        type: AJAX_TYPE.POST
    },
    // web用户退出
    [`${prefix}LogoutWeb`]: {
        url: '/web/crm/user/logoutWeb/:location',
        type: AJAX_TYPE.GET
    },
    // 忘记密码
    [`${prefix}ForgetPwd`]: {
        url: '/web/crm/user/forgetPwd',
        type: AJAX_TYPE.POST
    },
    // 忘记密码获取短信验证码
    [`${prefix}GetCodeForget`]: {
        url: '/web/crm/user/getCodeForget',
        type: AJAX_TYPE.POST
    },
    // 获取用户信息
    [`${prefix}GetInfo`]: {
        url: '/web/crm/user/mall/info',
        type: AJAX_TYPE.GET
    },

    // 获取用户身份状态
    [`${prefix}GetPrincipal`]: {
        url: '/web/crm/user/getPrincipal',
        type: AJAX_TYPE.GET
    },
    // 获取用户身份状态
    [`${prefix}AutoLogin`]: {
        url: '/web/crm/login/getLoginInfo',
        type: AJAX_TYPE.GET
    },
    // 登录获取短信验证码
    [`${prefix}Getcodeforlogin`]: {
        url: '/web/crm/user/getCodeForLogin',
        type: AJAX_TYPE.POST
    },

    // 获取用户信息
    [`${prefix}GetUserDetail`]: {
        url: '/api/isx/v1/authUser/getUserDetail',
        type: AJAX_TYPE.GET
    },

    // 获取用户信息
    [`${prefix}GetUserInfoDetail`]: {
        url: '/api/isx/v1/authUser/currentUser',
        type: AJAX_TYPE.GET
    },
    // 获取当前用户所有身份信息数据
    [`${prefix}GetuserIdentity`]: {
        url: '/api/isx/v1/authUser/userIdentity',
        type: AJAX_TYPE.GET
    },
    // 切换当前身份接口
    [`${prefix}SwitchUserIdentity`]: {
        url: '/api/isx/v1/authUser/switchUserIdentityAndGetUserInfo',
        type: AJAX_TYPE.POST
    },
    // 判断当前是否登录
    [`${prefix}GetUserTokenStatus`]: {
        url: '/api/isx/v1/authUser/getUserTokenStatus',
        type: AJAX_TYPE.GET
    },

    // 判断当前是否登录
    [`${prefix}SsoLogout`]: {
        url: '/api/isx/hdf/sso/logout',
        type: AJAX_TYPE.GET
    },

    // 获取用户身份企业 是否被驳回
    [`${prefix}UserCompanyDisableFlag`]: {
        url: '/api/isx/v1/authUser/currentUserCompanyDisableFlag',
        type: AJAX_TYPE.GET
    },

    // 获取平台logo
    [`${prefix}GetUserLogo`]: {
        url: '/api/open/v1/tenant/getTenantInfo',
        type: AJAX_TYPE.GET
    },




}
