/* eslint-disable */

/***
 *  V 1.1。 帮助中心 ，重新开发
 * 
 * 
 */
import {
    AJAX_TYPE
} from '../config';

// user service

const prefix = 'newHelp';

export default {
    // 获取 问题的目录 列表
    [`${prefix}CatalogList`]: {
        url: '/web/crm/helpCatalog/getHelpCatalogList',
        type: AJAX_TYPE.POST
    },


    // 查看帮助中心常见问题列表信息
    [`${prefix}MsgListPage`]: {
        url: '/web/crm/helpMessage/getHelpMessageListByPage',
        type: AJAX_TYPE.POST
    },

    [`${prefix}MsgDetail`]: {
        url: '/web/crm/helpMessage/getMessageDetail',
        type: AJAX_TYPE.GET
    },

    [`${prefix}AddFeedBack`]: {
        url: '/web/helpFeedback/addFeedback',
        type: AJAX_TYPE.POST
    },

    [`${prefix}UpdateFeedBack`]: {
        url: '/web/helpFeedback/updateFeedback',
        type: AJAX_TYPE.POST
    }
}