/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';


const prefix = 'comment';

export default {
    // 评价列表
    [`${prefix}List`]: {
        url: '/web/mgr/comment/shopCommentList',
        type: AJAX_TYPE.POST
    },
    //店铺评价信息
    [`${prefix}ShopEvaluate`]: {
        url: '/web/mgr/comment/shopEvaluate',
        type: AJAX_TYPE.GET
    },
    //商家回复   
    [`${prefix}ReplyComment`]: {
        url: '/web/mgr/comment/replyComment',
        type: AJAX_TYPE.POST
    }
}