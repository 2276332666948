/* eslint-disable */
import { API } from '@/http/api';
import http  from '@/http';
import axios from "axios";

export function throttle(func, context, duration = 2000) {
    let firstCall = true;
    let lastRunTime;

    return function () {
        if (firstCall) {
            func.apply(context, arguments);
            firstCall = false;
            lastRunTime = Date.now();
        } else {
            const currentTime = Date.now();
            if (currentTime - lastRunTime >= duration) {
                func.apply(context, arguments);
                lastRunTime = currentTime;
            }
        }
    }
}

/*
* 深度拷贝
* @param obj 需拷贝对象
* */
export const deepclone = (obj) => {
    const cloneObj = Judgetype(obj) === 'array' ? [] : {};
    Object.keys(obj).forEach(key => {
        if ((typeof obj[key] === 'object' || typeof obj[key] === 'function') && obj[key] != null) {
            cloneObj[key] = deepclone(obj[key])
        } else {
            cloneObj[key] = obj[key];
        }
    })
    return cloneObj
}

/*
* 判断类型
* @param obj 任何类型数据
* */
export function Judgetype(obj) {
    const TypesArr = ['Array', 'Date', 'RegExp', 'Object', 'Error'];
    let classtype = {};

    TypesArr.forEach(e => classtype['[object ' + e + ']'] = e.toLowerCase());

    if (obj == null) return String(obj)
    return typeof obj === 'object' ? classtype[Object.prototype.toString.call(obj)] || 'object' : typeof obj
}

/*
* 函数防抖 高频率操作只在最后一次执行 适用用户输入在输入完后才校验
* @param fn 回调函数
* @param wait 等待时间
* @param immediate 是否及时生效
* */
export function debounce(fn, wait, immediate) {
    let timer = null;

    return function () {
        const args = arguments,
            context = this;

        if (immediate && !timer) {
            fn.apply(context, args)
        }

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, wait)
    }
}

/*
* 数组拆解 可将多维数组拆解成一维数组
* */
Array.prototype.flat = function () {
    return this.toString().split(',').map(item => +item)
}

/*
* 改变对象的key 因为后端给予的key与你需要的不一样
* @param data后端给予的数据
* @param keyMap
* @param fliter 是否过滤掉其它不用的属性
* 比如 data为{id:'11',name:'张三'},keyMap为 {id:'key', name:'value'} 转化为 {key:'11',value:'张三'}
* **/
export function changeObjkey(data, keyMap, fliter) {

    const objs = Object.keys(data).reduce((newData, key) => {
        let newKey = fliter ? keyMap[key] : (keyMap[key] || key)
        newData[newKey] = data[key]
        return newData
    }, {})

    if (fliter) delete objs.undefined

    return objs
}

export function _typeof(type) {
    return Object.prototype.toString.call(type).replace('[object ', '').replace(']', '')
}

// Math.random()不安全，自己生成
Math.seed = new Date().getTime();
Math.randomNumberBySeed = function(max, min) { 
　　max = max || 1; 
 　 min = min || 0; 
　　Math.seed = (Math.seed * 9301 + 49297) % 233280;
　　
　　var rnd = Math.seed / 233280.0; 
　　return min + rnd * (max - min); 
}
// 生成唯一id的方法，copy的乾寓
export function getUuid() {
    var CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

    var chars = CHARS, uuid = [], i;
    var r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
            r = 0 | Math.randomNumberBySeed() * 16;
            uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
    }
    uuid = uuid.join('');
    //随机替换 “-”
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    for (var i = 0; i < 4; i++) {
        var id = Math.ceil(Math.randomNumberBySeed() * 35);
        uuid = uuid.replace("-", chars[id]);
    }
    return uuid;
}

/* 电话号码 空格分位 */
let new_value = ''
export function getNewPhone(e) {
    let tel = ''
    if (e) {
        if (e.target) {
            tel = e.target.value.replace(/\s+/g, '').replace(/[^\d]/g, '').replace(/(\d{3})(?=\d)/, '$1 ').replace(/(\d{4})(?=\d)/, '$1 ')
            if ((tel.length === 6 || tel.length === 11) && tel.length > e.target.selectionEnd && new_value.length < tel.length) {
                tel = tel.substr(0, e.target.selectionEnd - 1)
            }
            new_value = tel
        } else {
            tel = e.replace(/\s+/g, '').replace(/[^\d]/g, '').replace(/(\d{3})(?=\d)/, '$1 ').replace(/(\d{4})(?=\d)/, '$1 ')
        }
    }
    return tel
}

/* 电话号码 */
export function phoneAddSecret(tel) {
    tel = "" + tel;
    var ary = tel.split("");
    ary.splice(3, 4, "****");
    var tel1 = ary.join("");
    return tel1
}

export function getUrlParam(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const urlObj = window.location;
    const href = urlObj.href;
    const hash = urlObj.hash;
    const search = urlObj.search;
    var r = href.indexOf('#') > -1 ?
        hash && hash.split("?") && hash.split("?")[1] && hash.split("?")[1].match(reg)
        :
        search && search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

export function getRedirect_url() {
    const urlObj = window.location;
    const hash = urlObj.hash;
    const startIndex = hash.indexOf('?redirect_url=');
    if (startIndex == -1) {
        // redirect_url 放在search里
        const search = urlObj.search;
        const regRes = search.match(/redirect_url=([^&]+)(&|$)/);
        if (regRes) {
            return regRes[1];
        }
        return null;
    } else {
        return hash.substring(startIndex + 14, hash.length);
    }
}

export function mapToArray(map) {
    var list = [];
    for (var key in map) {
        list.push([key, map[key]]);
    }
    return list;
}

export function mapToSelectData(map) {
    var list = [];
    for (var key in map) {
        list.push({
            'value':key,
            'name': map[key]
        });
    }
    return list;
}

export const queryString = {
    /**
     * url参数转对象
     * @param  {String} str url参数
     * @return {Object}
     */
    parse: str => {
        var query = {};
        var pairs = (str[0] === "?" ? str.substr(1) : str).split("&");
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split("=");
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
        }
        return query;
    },

    /**
     * 对象序列化
     * @param  {Object} obj
     * @return {String}
     */
    stringify: obj => {
        if (!obj) return "";
        var pairs = [];

        for (var key in obj) {
            var value = obj[key];

            if (value instanceof Array) {
                for (var i = 0; i < value.length; ++i) {
                    pairs.push(
                        encodeURIComponent(key + "[" + i + "]") +
                        "=" +
                        encodeURIComponent(value[i])
                    );
                }
                continue;
            }

            pairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
        }

        return pairs.join("&");
    }
};

function querySensitiveWord(label, value, cb) {
    if (value) {     
        http({
           api: API.itemSensitiveWord,
           data: {
               word: value
           },
        }).then(({ code  }) => {
           if (code === '-1') {
                cb(new Error('请输入正确的'+label));
           }else{
               cb();
           }
        }).catch(()=>{
            cb();
        });
    }else{
        cb();
    }
}

export function getSensitiveWordValidator(label, delayTime = 1000) {
    let lastCallTime= null;
    let timer = null;
    let currentValue = '';

    let lastCb = null;
    let currentCb = null;
    
    return function (rule, value, cb) {
        currentValue = value;

        lastCb = currentCb;
        currentCb = cb;

        if (lastCallTime && Date.now() - lastCallTime< delayTime) {
            lastCb && lastCb();
        }else{
            lastCallTime = Date.now();
            timer = setTimeout(() => {
                querySensitiveWord(label, currentValue, currentCb);
            }, delayTime);
        }
    }
}

export function getLogisticsInfo(data, type) {
    // no = '75312165465979'
    // name = 'zhongtong'
    // return new Promise(resolve => {
    //     axios({
    //         method: 'get',
    //         withCredentials: false,
    //         dataType: 'json',
    //         url: 'https://route.showapi.com/64-19',
    //         params: {
    //           showapi_appid: '197047', //自己的appid
    //           showapi_sign: 'df954aca938c4bae9659572e902c6293', //应用的密钥secret
    //           com: name,
    //           nu: no,
    //         }
    //     }).then(res => {
    //         console.log('timeList res', res)
    //         if (res && res.showapi_res_code == 0) {
    //             const dataList = res.showapi_res_body.data || []
    //             const timeList = dataList.map((per, index) => {
    //                 return {
    //                     id: index,
    //                     time: per.time,
    //                     text: per.context
    //                 }
    //             })
    //             console.log('timeList', timeList)
    //             resolve(timeList)
    //         }
    //     }).catch(err => {
    //         console.log('error', err)
    //         resolve([])
    //     })
    // })

   return new Promise(resolve => http({
        api: type == 1 ? API.expressOrder:API.expressAfter,
        data: data,
    }).then(res => {
        if (res && res.success) {
            const dataList = res.data || []
            const timeList = dataList.map((per, index) => {
                return {
                    id: index,
                    time: per.acceptTime,
                    text: per.acceptStation
                }
            })
            resolve(timeList)
        }
    }).catch(err => {
        console.log('error', err)
        resolve([])
    })) 
}
