/* eslint-disable */
import { AUTH_KEYS } from "@/utils/auth";
import userUtil from "@/utils/user";
import { func } from "prop-types";
const SUPPLIER_AUTH = "33336666";

var sConfig = [
	{
		auth: AUTH_KEYS.SUPPLIER_AUTH,
		title: '运营管理',
		route: '/Supplier',
		icon: '',
		subs: [
			
			{
				auth: AUTH_KEYS.SUPPLIER_AUTH_GOODS,
				title: '商品管理',
				route: '/SupplierGoods',
				iconfont: 'iconSimilarproducts-fill',
				autoWidth: true,
				component: 'SupplierGoods',
				subs: [
					{
						title: '创建商品',
						route: '/create',
						component: 'SupplierGoodsCreate'
					},
					{
						title: '商品详情',
						route: '/:id',
						component: 'SupplierGoodsCreate'
					},
					{
						title: '商品详情',
						route: '/modify/:id',
						component: 'SupplierGoodsCreate'
					}
				]
			},
			{
				auth: AUTH_KEYS.SUPPLIER_AUTH_ORDER,
				title: '交易管理',
				route: '/transaction',
				iconfont: 'iconorder-fill',
				autoWidth: true,
				subs: [
					{
						//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
						title: '订单管理',
						route: '/order',
						component: 'SupplierOrder',
						autoWidth: true,
						subs: [
							{
								title: '订单详情',
								route: '/:id',
								component: 'SupplierOrderDetail',
								autoWidth: true,
							},
						]
					},
					{
						title: '评价管理',
						route: '/comment',
						component: 'CommentList',
						autoWidth: true
					},
					{
						title: '发货管理',
						route: '/ship',
						component: 'ShipList',
						autoWidth: true,
						subs: [
							{
								title: '立即发货',
								route: '/:id',
								autoWidth: true,
								component: 'ShipImmediate'
							}
						]
					},
					{
						// auth: AUTH_KEYS.OPERATE_CONTRACT,
						title: '售后管理',
						route: '/AfterSales',
						autoWidth: true,
						component: 'AfterSales',
						subs: [
							{
								title: '售后详情',
								route: '/details',
								icon: '',
								autoWidth: true,
								component: 'AfterSalesDetail'
							},
						]
					}
				]
			},
			{
				auth: AUTH_KEYS.SUPPLIER_AUTH_ADDRESS,
				title: '物流管理',
				route: '/logistics',
				iconfont: 'icontracking_filled',
				autoWidth: true,
				subs: [
					{
						//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
						title: '运费模板',
						route: '/TemplateList',
						component: 'TemplateList',
						autoWidth: true,
						subs: [
							{
								title: '创建模板',
								route: '/create',
								component: 'TemplateNewEdit',
								autoWidth: true
							}
						]
					},
					{
						title: '地址库',
						route: '/addresses',
						component: 'LogisticsAddress',
						autoWidth: true
					},
				]
			},
			{
				auth: AUTH_KEYS.SUPPLIER_AUTH_SHOPS,
				title: '店铺管理',
				route: '/shops',
				iconfont: 'icondianpuguanli',
				autoWidth: true,
				subs: [
					{
						auth: AUTH_KEYS.SUPPLIER_AUTH_SHOPS_MSG,
						title: '店铺信息',
						route: '/shops_msg',
						component: 'ShopsMsg',
						autoWidth: true,
						subs: [
							{
								title: '店铺信息',
								route: '/shops_edit',
								component: 'ShopsEdit',
								autoWidth: true
							}
						]
					},
					{
						auth: AUTH_KEYS.SUPPLIER_AUTH_SHOPS_CHANGE,
						title: '店铺装修',
						route: '/shops_trim',
						autoWidth: true,
						subs: [
							{
								title: '类目管理',
								route: '/CategoryManage',
								component: 'CategoryManage',
								autoWidth: true
							}
						]
					},
					{
						auth: AUTH_KEYS.SUPPLIER_AUTH_SHOPS_FANS,
						title: '店铺粉丝',
						route: '/shops_fans',
						component: 'ShopsFans',
						autoWidth: true,
						subs: []
					},
				]
			},
			{
				title: '报价管理',
				route: '/quote',
				component: 'QuoteManager',
				iconfont: 'icontoubiaoguanli',
				autoWidth: true,
				subs: [
					{
						title: '报价详情',
						route: '/quoteDetail/:id',
						component: 'QuoteDetail',
						autoWidth: true,
					}
				]
			},
		/*	{
				title: '员工专享',
				route: '/actives',
				component: 'EmployeeExclusive',
				iconfont: 'iconyuangongzhuanxiang',
				autoWidth: true,
			},
			{
				title: '企业购',
				route: '/enterprise',
				component: 'EnterprisePurchase',
				iconfont: 'iconiconfont_QYG-01',
				autoWidth: true,
			},*/
			// {
			// 	title: '数十方专享',
			// 	route: '/pinganVip',
			// 	component: 'PinganVip',
			// 	iconfont: 'iconpinganhuiyuan',
			// 	autoWidth: true,
			// },
			{
				auth: AUTH_KEYS.SUPPLIER_AUTH_BID,
				title: '投标管理',
				route: '/bid',
				iconfont: 'icontoubiaoguanli',
				autoWidth: true,
				component: 'BidList',
				subs: [
					{
						//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
						title: ' 预审详情',
						/**
						 * id: 投标报名Code
						 */
						route: '/detail/:id',
						component: 'BidDetail',
						autoWidth: true,
					},
					{
						//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
						title: '标书详情',
						/**
						 * id: 公告Code
						 */
						route: '/tenderDetail/:id',
						component: 'TenderDetail',
						autoWidth: true,
					},
					{
						//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
						title: '上传标书',
						/**
						 * id: 公告Code
						 */
						route: '/tenderEdit/:id',
						component: 'TenderDetail',
						autoWidth: true,
					},
					{
					  title: "申请入库",
					  /**
					   * id: 公告Code
					   */
					  route: "/warehousingDetail/:id",
					  component: "WarehousingDetail",
					  autoWidth: true
					}
				]
			},
			// {
			// 	// auth: AUTH_KEYS.SUPPLIER_AUTH_BID,
			// 	title: "资金账户",
			// 	route: "/fund",
			// 	iconfont: "iconmenu_fund",
			// 	autoWidth: true,
			// 	subs: [
			// 	{
			// 		//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
			// 		title: '货款账户',
			// 		route: '/detail',
			// 		component: 'MoneyDetail',
			// 		autoWidth: true,
			// 		subs: [
			// 		{
			// 			title: '提现',
			// 			route: '/cashout',
			// 			component: 'Cashout',
			// 			autoWidth: true
			// 		},
			// 		{
			// 			title: '线上详情',
			// 			route: '/account',
			// 			component: 'Account',
			// 			autoWidth: true
			// 		},
			// 		{
			// 			title: '线下详情',
			// 			route: '/accountOff',
			// 			component: 'AccountOff',
			// 			autoWidth: true
			// 		}
			// 		]
			// 	},
			// 	{
			// 		title: " 保证金",
			// 		route: "/deposit",
			// 		component: "DepositList",
			// 		autoWidth: true
			// 	},
			// 	{
			// 		//auth: AUTH_KEYS.OPERATE_FINANCE_MENU_AUDIT,
			// 		title: '银行卡',
			// 		route: '/bankcard',
			// 		component: 'BankCard',
			// 		autoWidth: true,
			// 		subs: [
			// 		{
			// 			title: '提现',
			// 			route: '/addbank',
			// 			component: 'Addbank',
			// 			autoWidth: true
			// 		},
			// 		]
			// 	}
			// 	]
			// },
			{
				auth: AUTH_KEYS.SUPPLIER_AUTH_REPORTFORM,
				title: '报表中心',
				route: '/report',
				iconfont: 'iconbaobiaozhongxin',
				subs: [
					{
						auth: AUTH_KEYS.OPERATE_REPORTFORM_SPUREPORT,
						title: '商品SPU报表',
						route: '/spuReport',
						icon: '',
						autoWidth: true,
						component: 'SpuReport'
					},
					{
						auth: AUTH_KEYS.OPERATE_REPORTFORM_SKUREPORT,
						title: '商品SKU报表',
						route: '/skuReport',
						icon: '',
						autoWidth: true,
						component: 'SkuReport'
					},
					{
						auth: AUTH_KEYS.OPERATE_REPORTFORM_ORDER_REPORT,
						title: '订单报表',
						route: '/orderReport',
						icon: '',
						autoWidth: true,
						component: 'OrderReport'
					}
				]
			}/*,
			{
				auth: '',
				title: '签约管理',
				route: '/elec',
				iconfont: 'iconmenu_approval',
				component: 'ElecSignList',
				autoWidth: true,	
				subs: [
					{
						title: '生成合同',
						route: '/confirmsign',
						component: 'ConfirmSignInfo',
						autoWidth: true,
					},
					{
						title: '合同签署',
						route: '/:id',
						component: 'ContractSign',
						autoWidth: true,
					}
				]			
			}*/
		]
	},
	{
		auth: AUTH_KEYS.OPERATE_DATAWATCH,
		title: '数据概览',
		route: '/data',
		// iconfont: 'iconmenu_reports',
		subs: [
			{
				auth: AUTH_KEYS.OPERATE_DATAWATCH_GOODS,
				title: '商品',
				route: '/goods',
				iconfont: 'iconSimilarproducts-fill',
				autoWidth: true,
				component: 'GoodsOverview'
			},
			{
				auth: AUTH_KEYS.OPERATE_DATAWATCH_ORDER,
				title: '订单',
				route: '/orderoverview',
				iconfont: 'iconorder-fill',
				autoWidth: true,
				component: 'OrderOverview'
			}
		]
	}	
]

const supplierConfig = {
	route: '/supplier',
	subs: sConfig
} 
function setRoute(config = supplierConfig) {
  if (config.subs) {
    const baseRoute = config.route;
    for (const subConfig of config.subs) {
      subConfig.route = baseRoute + subConfig.route;
      setRoute(subConfig);
    }
  }
}
setRoute(supplierConfig)
function getUserAuthRouteConfig(config, routeConfig) {
  const baseRoute = config.route; // /supplier
  const baseRouteConfig = {
    ...config
  };
  baseRouteConfig.subs = [];
  if (config.subs) {
    for (const subConfig of config.subs) {
    //   if (!subConfig.auth || userUtil.checkAuthByKey(subConfig.auth)) {
        baseRouteConfig.subs.push(subConfig.route);
        getUserAuthRouteConfig(subConfig, routeConfig);
    //   }
    }
  }
  routeConfig[baseRoute] = baseRouteConfig;
}
let supplierRouteConfig = {};

function checkActivityRouteAvaliable(config) {
	const supplierManageConfig = config.find(per => per.route === '/supplier/Supplier')
	const employeeIndex = supplierManageConfig.subs.findIndex(per => per.route === '/supplier/Supplier/actives')
	if (!userUtil.checkActivityAvaliable().employeeAvaliable || userUtil.getCookie('employeeOpen')!=='1') {
		supplierManageConfig.subs.splice(employeeIndex, 1)
	}
	const enterpriseIndex = supplierManageConfig.subs.findIndex(per => per.route === '/supplier/Supplier/enterprise')
	if (!userUtil.checkActivityAvaliable().enterpriseAvaliable || userUtil.getCookie('enterpriseOpen')!=='1') {
		supplierManageConfig.subs.splice(enterpriseIndex, 1)
	}
	const pinganVipIndex = supplierManageConfig.subs.findIndex(per => per.route === '/supplier/Supplier/pinganVip')
	if (!userUtil.checkActivityAvaliable().pinganVipAvaliable || userUtil.getCookie('pinganVipOpen')!=='1') {
		supplierManageConfig.subs.splice(pinganVipIndex, 1)
	}
	// console.log('** checkActivityRouteAvaliable **', config)
	return config
}
export function refreshRoute() {
	// refreshConfig()
	supplierRouteConfig = {};
	// supplierConfig.subs = checkActivityRouteAvaliable(supplierConfig.subs);
	getUserAuthRouteConfig(supplierConfig, supplierRouteConfig);
}
refreshRoute(); // 直接刷新页面

// console.info(supplierRouteConfig, 'sdfasdfasfds')
export default {
	supplierRouteConfig
};
