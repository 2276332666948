/* eslint-disable */
// 商品管理
import { AJAX_TYPE } from '../config';
import { Progress } from 'antd';

const baseURL = '';
// process.env.NODE_ENV == "development" ? '' : 'http://oms-mall.st1.idf10.com.cn/'

const prefix = 'item';

export default {
	[`${prefix}Add`]: {
		url: baseURL + '/web/mgr/item/add',
		type: AJAX_TYPE.POST
	},
	[`${prefix}Detail`]: {
		url: baseURL + '/web/mgr/item/detail',
		type: AJAX_TYPE.POST
	},
	[`${prefix}QueryDefaultSpecList`]: {
		url: baseURL + '/web/mgr/item/queryDefaultSpecList',
		type: AJAX_TYPE.POST
	},
	[`${prefix}Update`]: {
		url: baseURL + '/web/mgr/item/upd',
		type: AJAX_TYPE.POST
	},
	// 商品列表
	[`${prefix}GoodsList`]: {
		url: baseURL + '/web/mgr/item/queryList',
		type: AJAX_TYPE.POST
	},
	// 商品上架
	[`${prefix}GoodsOnshelf`]: {
		url: baseURL + '/web/mgr/item/putOnShelves',
		type: AJAX_TYPE.POST
	},
	// 商品下架
	[`${prefix}GoodsOffshelf`]: {
		url: baseURL + '/web/mgr/item/takeOffShelves',
		type: AJAX_TYPE.POST
	},
	// 批量删除
	[`${prefix}GoodsDelete`]: {
		url: baseURL + '/web/mgr/item/dels',
		type: AJAX_TYPE.POST
	},
	// 修改商品名称
	[`${prefix}GoodsNameChange`]: {
		url: baseURL + '/web/mgr/item/updItemName',
		type: AJAX_TYPE.POST
	},
	// 获取sku
	[`${prefix}GetGoodsSkuByItemid`]: {
		url: baseURL + '/web/mgr/item/getSkuByItemId',
		type: AJAX_TYPE.POST
	},
	// 更新价格和库存
	[`${prefix}UpdateSkuPriceAndStock`]: {
		url: baseURL + '/web/mgr/sku/updateSku',
		type: AJAX_TYPE.POST
	},
	// 新增标签
	[`${prefix}AddLabel`]: {
		url: baseURL + '/web/mgr/item/addLabel',
		type: AJAX_TYPE.POST
	},
	// 标签列表
	[`${prefix}QueryLabelList`]: {
		url: baseURL + '/web/mgr/item/queryLabelList',
		type: AJAX_TYPE.POST
	},
	// 修改标签
	[`${prefix}UpdLabel`]: {
		url: baseURL + '/web/mgr/item/updLabel',
		type: AJAX_TYPE.POST
	},
	// 删除标签
	[`${prefix}DelLabel`]: {
		url: baseURL + '/web/mgr/item/delLabel',
		type: AJAX_TYPE.POST
	},
	// 批量设置标签
	[`${prefix}BatchItemLabel`]: {
		url: baseURL + '/web/mgr/item/batchItemLabel',
		type: AJAX_TYPE.POST
	},
	// 敏感词监测
	[`${prefix}SensitiveWord`]: {
		url: baseURL + '/web/mgr/item/sensitiveWord',
		type: AJAX_TYPE.POST
	},


	[`${prefix}TransCustomize`]: {
		url: baseURL + '/web/mgr/transCustomize/query',
		type: AJAX_TYPE.POST
	}
};
