/* eslint-disable */
import { AJAX_TYPE } from '../config';

const prefix = 'address';

const baseURL = '';
// process.env.NODE_ENV == 'development' ? '' : 'http://oms-mall.st1.idf10.com.cn';

export default {
	// 查询地址列表
	[`${prefix}QueryAddress`]: {
		url: baseURL + '/web/mgr/address/queryAddress',
		type: AJAX_TYPE.POST
	},
	// 查询地址详情
	[`${prefix}GetByCode`]: {
		url: baseURL + '/web/mgr/address/getByCode/:code',
		type: AJAX_TYPE.GET
	},
	// 保存 新增地址
	[`${prefix}Save`]: {
		url: baseURL + '/web/mgr/address/save',
		type: AJAX_TYPE.POST
	},
	// 编辑 地址
	[`${prefix}Edit`]: {
		url: baseURL + '/web/mgr/address/edit',
		type: AJAX_TYPE.POST
	},
	// 删除地址
	[`${prefix}Delete`]: {
		url: baseURL + '/web/mgr/address/delete',
		type: AJAX_TYPE.POST
	},
	

};
