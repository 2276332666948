/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 合同

const prefix = 'contract';

export default {
    // 新增合同
    [`${prefix}Add`]: {
        url: '/web/contract/add',
        type: AJAX_TYPE.POST
    },
    // 检查合同日期
    [`${prefix}CheckPeriod`]: {
        url: '/web/contract/checkPeriod',
        type: AJAX_TYPE.POST
    },
    // 不区分审批权限情况统计合同审批数
    [`${prefix}Approvecount`]: {
        url: '/web/contract/approve/count',
        type: AJAX_TYPE.POST
    },
    // 根据合同id查询合同详情&amp;审批信息
    [`${prefix}Approveinfo`]: {
        url: '/web/contract/approve/info',
        type: AJAX_TYPE.POST
    },
    // 审批列表
    [`${prefix}Approvelist`]: {
        url: '/web/contract/approve/list',
        type: AJAX_TYPE.POST
    },
    // 审批列表汇总数据
    [`${prefix}ApproveCount`]: {
        url: '/web/contract/approve/count',
        type: AJAX_TYPE.POST
    },
    // 查看审批流程
    [`${prefix}Approvenode`]: {
        url: '/web/contract/approve/node',
        type: AJAX_TYPE.POST
    },
    // 变更合同
    [`${prefix}Change`]: {
        url: '/web/contract/change',
        type: AJAX_TYPE.POST
    },
    // 计算账单明细
    [`${prefix}CompactBillDetail`]: {
        url: '/web/contract/compactBillDetail',
        type: AJAX_TYPE.POST
    },
    // (终止/驳回/通过)审批合同申请
    [`${prefix}Contractapprovecallback`]: {
        url: '/web/contract/approve/callback',
        type: AJAX_TYPE.POST
    },
    // 合同查询初始化
    [`${prefix}SearchInit`]: {
        url: '/web/contract/contractSearchInit',
        type: AJAX_TYPE.GET
    },
    // 下载合同附件
    [`${prefix}DownloadMedia`]: {
        url: '/web/contract/downLoad',
        type: AJAX_TYPE.POST
    },
    // 下载合同条款
    [`${prefix}Download`]: {
        url: '/web/contract/download/:enCode',
        type: AJAX_TYPE.GET
    },
    // 修改合同
    [`${prefix}Edit`]: {
        url: '/web/contract/edit',
        type: AJAX_TYPE.POST
    },
    // 修改合同作废申请
    [`${prefix}EditInvalidApply`]: {
        url: '/web/contract/editInvalidApply',
        type: AJAX_TYPE.POST
    },
    // 获取合同数量
    [`${prefix}SumTotal`]: {
        url: '/web/contract/getContractSumTotal',
        type: AJAX_TYPE.GET
    },
    // 决策、模板信息
    [`${prefix}GetDecisionInfo`]: {
        url: '/web/contract/getDecisionInfo/:projectCode',
        type: AJAX_TYPE.GET
    },
    // 历史（关联）合同
    [`${prefix}History`]: {
        url: '/web/contract/history/:enCode',
        type: AJAX_TYPE.GET
    },
    // 合同详情
    [`${prefix}Info`]: {
        url: '/web/contract/info/:enCode',
        type: AJAX_TYPE.GET
    },
    // 合同详情的收支
    [`${prefix}BillList`]: {
        url: '/web/contract/getBillList',
        type: AJAX_TYPE.POST
    },
    // 初始化(新增合同)
    [`${prefix}Init`]: {
        url: '/web/contract/init',
        type: AJAX_TYPE.GET
    },
    // 初始化申请退租
    [`${prefix}InitRentWithdrawalApply`]: {
        url: '/web/contract/initRentWithdrawalApply/:enCode',
        type: AJAX_TYPE.GET
    },
    // 合同作废申请
    [`${prefix}InvalidApply`]: {
        url: '/web/contract/invalidApply',
        type: AJAX_TYPE.POST
    },
    // 合同操作记录
    [`${prefix}OperateRecord`]: {
        url: '/web/contract/operateRecord',
        type: AJAX_TYPE.POST
    },
    // 续租
    [`${prefix}Renew`]: {
        url: '/web/contract/renew',
        type: AJAX_TYPE.POST
    },
    // 合同申请退租
    [`${prefix}RentWithdrawalApply`]: {
        url: '/web/contract/rentWithdrawalApply',
        type: AJAX_TYPE.POST
    },
    // 合同查询
    [`${prefix}Search`]: {
        url: '/web/contract/search',
        type: AJAX_TYPE.POST
    },
    // 合同统计
    [`${prefix}Statistics`]: {
        url: '/web/contract/statistics',
        type: AJAX_TYPE.POST
    },
    // 合同和审批合同总数
    [`${prefix}SumTotal`]: {
        url: '/web/contract/getContractSumTotal',
        type: AJAX_TYPE.GET
    },
    // 可查看合同客户集合,支持联想
    [`${prefix}GetCustomerList`]: {
        url: '/web/contract/getCustomerList',
        type: AJAX_TYPE.POST
    },
    // 查看租客的合同集合
    [`${prefix}GetContractList`]: {
        url: '/web/contract/getContractList/:encode',
        type: AJAX_TYPE.POST
    }
}