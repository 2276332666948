/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// department service

const prefix = 'department';

export default {
    // 录入部门
    [`${prefix}Add`]: {
        url: '/web/department/add',
        type: AJAX_TYPE.POST
    },
    // 删除部门
    [`${prefix}Delete`]: {
        url: '/web/department/delete/:idCode',
        type: AJAX_TYPE.GET
    },
    // 部门详情
    [`${prefix}Detail`]: {
        url: '/web/department/detail/:idCode',
        type: AJAX_TYPE.GET
    },
    // 编辑部门
    [`${prefix}Edit`]: {
        url: '/web/department/edit',
        type: AJAX_TYPE.POST
    },
    // 获取部门树
    [`${prefix}GetDeptTree`]: {
        url: '/web/department/getDeptTree',
        type: AJAX_TYPE.GET
    },
    // 查询子部门列表
    [`${prefix}GetList`]: {
        url: '/web/department/getList',
        type: AJAX_TYPE.POST
    },
    // 查询部门项目集合 isParent : 是否查询父部门项目集合
    [`${prefix}GetProjects`]: {
        url: '/web/department/getProjects/:departmentCode',
        type: AJAX_TYPE.GET
    },
    // 根据部门名称查询
    [`${prefix}Search`]: {
        url: '/web/department/search',
        type: AJAX_TYPE.POST
    },
    [`${prefix}Staff`]: {
        url: '/web/user/selectList',
        type: AJAX_TYPE.POST
    },
}