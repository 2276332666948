/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 账单 付款收款

const prefix = 'billpay';

export default {
    // 审批
    [`${prefix}Audit`]: {
        url: '/web/bill/pay/audit',
        type: AJAX_TYPE.POST
    },
    // 获取审批记录
    [`${prefix}AuditList`]: {
        url: '/web/bill/pay/auditList',
        type: AJAX_TYPE.POST
    },
    // 废弃支付款
    [`${prefix}Drop`]: {
        url: '/web/bill/pay/drop',
        type: AJAX_TYPE.POST
    },
    // 获取支付款记录
    [`${prefix}GetPaymentList`]: {
        url: '/web/bill/pay/getPaymentList',
        type: AJAX_TYPE.POST
    },
    // 获取该账单被驳回的收付款记录
    [`${prefix}GetRejectedPayment`]: {
        url: '/web/bill/pay/getRejectedPayment',
        type: AJAX_TYPE.GET
    },
    // 发起支付款
    [`${prefix}Pay`]: {
        url: '/web/bill/pay/pay',
        type: AJAX_TYPE.POST
    },
    // 重新发起支付款
    [`${prefix}Repay`]: {
        url: '/web/bill/pay/repay',
        type: AJAX_TYPE.POST
    },
     // 获取支付款记录
     [`${prefix}GetPaymentVoList`]: {
        url: '/web/bill/pay/getPaymentList',
        type: "AJAX_TYPE.POST"
    },
    // 收付款审批统计
    [`${prefix}AuditCount`]: {
        url: '/web/bill/pay/auditCount',
        type: AJAX_TYPE.POST
    },
    // 收支详情
    [`${prefix}GetById`]: {
        url: '/web/bill/pay/getById',
        type: AJAX_TYPE.GET
    },
   
}