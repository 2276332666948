/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 账单退款

const prefix = 'billrefund';

export default {
    // audit
    [`${prefix}Audit`]: {
        url: '/web/bill/refund/audit',
        type: AJAX_TYPE.POST
    },
    // 退款审批记录
    [`${prefix}AuditList`]: {
        url: '/web/bill/refund/auditList',
        type: AJAX_TYPE.POST
    },
    // 退款记录导出excel
    [`${prefix}Export`]: {
        url: '/web/bill/refund/export',
        type: AJAX_TYPE.POST
    },
    // getList
    [`${prefix}GetList`]: {
        url: '/web/bill/refund/getList',
        type: AJAX_TYPE.POST
    },
    // getRejectedRefund
    [`${prefix}GetRejectedRefund`]: {
        url: '/web/bill/refund/getRejectedRefund',
        type: AJAX_TYPE.GET
    },
    // refund
    [`${prefix}Refund`]: {
        url: '/web/bill/refund/refund',
        type: AJAX_TYPE.POST
    },
    // update
    [`${prefix}Update`]: {
        url: '/web/bill/refund/update',
        type: AJAX_TYPE.POST
    },
    // 退款审批统计
    [`${prefix}AuditCount`]: {
        url: '/web/bill/refund/auditCount',
        type: AJAX_TYPE.POST
    },
    // 获取退款详情
    [`${prefix}GetById`]: {
        url: '/web/bill/refund/getById',
        type: AJAX_TYPE.GET
    },
    // 删除退款账单
    [`${prefix}DropRefund`]: {
        url: '/web/bill/refund/dropRefund',
        type: AJAX_TYPE.GET
    },
}