/* eslint-disable */
// 用户操作模块
import storage, {
  STORAGE_KEYS
} from './storage';
import http, { ajax } from '@/http';
import { API } from '@/http/api';
import { message } from "antd";

let mallUrl = '';  // 商城地址
let crmUrl = '';  // crm地址
let mgrUrl = ''; // 管理中心
let logisticsUrl = ''; // 物流中心
let wholeDomain = '';  //
let downloadUrl = ''; // 文件下载地址
let bcMallUrl = '';
let tMallUrl = '';
let omsUrl = '';
let whiteUrlArray = [];
let isOurStation = true;
let ishfjl = true;
let istflq = true;
let istfsn = true;
let istfgc = true;
let islqemall = true;




let host = window.location.host; // host;
let origin = window.location.origin;
// 获取 domain，拿到 .ipo.com / .idf10.com / .idf10.com.cn 等后缀；
let dianIndex = host.indexOf('.'); // 
let domainStr = host.substring(dianIndex, host.length);
console.log(domainStr, 'domainStr');
// 获取前缀，先判断是否有 ‘-’，有 ‘-’ 需要拼接；无 ‘-’ ，判断是uat的 ipo.com 还是生产的 idf10.com；
let gangIndex = host.indexOf('-');
let isUat = host.indexOf('id10.com.cn') > -1;
let hasGang = gangIndex > -1;
let gangUrl = hasGang ? host.substring(0, gangIndex + 1) : '';
// let httpStr = host.indexOf('idf10.com') > -1 ? 'https://' : 'http://';
let httpStr = origin.indexOf("https") > -1 ? "https://" : "http://";

// let mallSmStr = gangUrl ? 'mall' : isYsc ? 'ysc' : 'www';
mallUrl = domainStr.indexOf('shudaowl') > -1 ? `https://tfdc.shudaowl.com` : `${httpStr}${gangUrl}${hasGang ? 'mall' : 'www'}${domainStr}`;
bcMallUrl = `${httpStr}${host}`;
mgrUrl = `${httpStr}${gangUrl}mc${domainStr}`;
crmUrl = `${httpStr}${gangUrl}crm${domainStr}`;
logisticsUrl = `${httpStr}${gangUrl}logistics${domainStr}`;
wholeDomain = domainStr;
tMallUrl = `${httpStr}${host}`;
omsUrl = `${httpStr}${host}`;

console.log(mallUrl, 'mallUrl');

isOurStation = !hasGang;
ishfjl = host.indexOf('hfjl') >-1;
istflq = host.indexOf('tflq') >-1;
istfsn = host.indexOf('tfsn') >-1;
istfgc = host.indexOf('tfgc') >-1;
islqemall = host.indexOf('lqe') >-1;

// let idx = window.location.host.indexOf('oms.ipo');
// idx = idx == -1 ? window.location.host.indexOf('supplier.idf10') : idx;
// let muchLesseeFirstHost = '';
// if (idx !== 0) {
//     muchLesseeFirstHost = window.location.host.substring(0, idx);
// }
// if (window.location.host.indexOf('.ipo.com') !== -1) {
//   mallUrl = 'http://'+ muchLesseeFirstHost +'ysc.ipo.com';
//   bcMallUrl = 'http://'+ muchLesseeFirstHost +'ysc.ipo.com';
//   tMallUrl = 'http://'+ muchLesseeFirstHost +'ysc.ipo.com';
//   omsUrl = 'http://'+ muchLesseeFirstHost +'oms.ipo.com';
//   crmUrl = 'http://'+ muchLesseeFirstHost +'crm.ipo.com';
//   wholeDomain = '.ipo.com';
//   downloadUrl = '';

// } else if (window.location.host.indexOf('.idf10.com') !== -1) {
//   mallUrl = 'https://'+ muchLesseeFirstHost +'idf10.com';
//   bcMallUrl = 'https://'+ muchLesseeFirstHost +'supplier.idf10.com';
//   crmUrl = 'https://'+ muchLesseeFirstHost +'crm.idf10.com';
//   wholeDomain = '.idf10.com';
//   downloadUrl = '';
//   tMallUrl = 'https://'+ muchLesseeFirstHost +'idf10.com';
//   omsUrl = 'https://'+ muchLesseeFirstHost +'supplier.idf10.com';
// }  else  {
//   mallUrl = 'http://ysc-local.ipo.com:3000';
//   bcMallUrl = 'http://oms.ipo.com';
//   crmUrl = 'http://crm.ipo.com';
//   wholeDomain = 'localhost';
//   downloadUrl = '';
//   tMallUrl = 'http://oms.ipo.com';
//   omsUrl = 'http://oms.ipo.com';
// }

whiteUrlArray = [mallUrl, bcMallUrl, tMallUrl, omsUrl];

const userKey = STORAGE_KEYS.USER;

const emptyObject = {};

function login(userInfo) {
  storage.set(userKey, userInfo);
}

function addUserInfo(userData) {
  let userInfo = storage.get(userKey);
  for (let key in userData) {
    if (userData[key]) {
      userInfo[key] = userData[key];
    }
  }
  storage.set(userKey, userInfo);
}

function hasOmsYuanQuAuth(data) {
  let userInfo = data || get();
  if (
    userInfo &&
    userInfo.userPrinciples &&
    userInfo.userPrinciples.indexOf("3") > -1
  ) {
    return true;
  } else {
    return false;
  }
}

function hasSupplierAuth(data) {
  let userInfo = data || get();
  if (userInfo && userInfo.userPrinciples && userInfo.userPrinciples.indexOf('2') > -1) {
    return true;
  } else {
    return false;
  }
}

function canComeInOms(data) {
  let userInfo = data || get();
  if (
    userInfo &&
    userInfo.userPrinciples &&
    (userInfo.userPrinciples.indexOf("3") > -1 ||
      userInfo.userPrinciples.indexOf("2") > -1)
  ) {
    return true;
  } else {
    return false;
  }
}

function isLogin() {
  return ajax({
      api: API.userGetUserTokenStatus,
      showLoading: true
  }).then(res => {
      let result = res.data;
      if (result&&result.code === 200 && result.data) {
          return true;
      } else {
        message.error("登录超时，请重新登录")
        setTimeout(()=> {
          goLogin()
        }, 1000)
      }
  });

}

function logout() {
  ajax({
      api: API.userSsoLogout,
      showLoading: true
  }).then(res => {
      if (res.code === 200) {
        goLogin()
      } else {
      }
  });

}

function get() {
  let user = storage.get(userKey);
  if (user) {
    return user;
  } else {
    return http({
      api: API.userGetUserDetail,
      showLoading: true
    }).then(res => {
      if (res.code === 200) {
        login(res.data.profile)
        return res.data.profile
      }
    })

  }

}

// 获取用户信息
function getUserInfo(cb){
    return http({
      api: API.userGetUserInfoDetail,
      showLoading: true
    }).then(res => {
      if (res.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        cb && cb(res.data)
      }
    })

}

function getName() {
  const user = get() || emptyObject;
  return user.userName || "";
}

function getIdCode() {
  const user = get() || emptyObject;
  return user.idCode || "";
}

function getAuths() {
  const user = get() || emptyObject;
  return user.functionList || [];
}

function checkAuthByKey(authKey) {
  const userAuths = getAuths();
  return userAuths.includes(authKey);
}

function hasAuth(authCodes, hasAny = true) {
  if (!Array.isArray(authCodes)) {
    authCodes = [authCodes];
  }

  const userAuths = getAuths();
  if (hasAny) {
    return authCodes.some(code => userAuths.includes(code));
  }

  return authCodes.every(code => userAuths.includes(code));
}

function isManager() {
  const user = get() || emptyObject;
  return user.isManager;
}

function setCookieDomin(name, value) {
  //设置名称为name,值为value的Cookie
  const cookieMsg = name + "=" + value + ";path=/;domain=" + wholeDomain;
  document.cookie = cookieMsg;
}

function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

function checkActivityAvaliable() {
  const user = get() || emptyObject;
  const employeeActivity = user.listPromotionVoList && user.listPromotionVoList.find(per => per.code === 'jzr13FqdpLk')
  const enterpriseActivity = user.listPromotionVoList && user.listPromotionVoList.find(per => per.code === '1QFpcUgueU4')
  const pinganVipActivity = user.listPromotionVoList && user.listPromotionVoList.find(per => per.code === 'hg0Ktk1R0Tc')
  return {
    employeeAvaliable: employeeActivity && employeeActivity.offStatus === 1, //1 开启 2 关闭
    enterpriseAvaliable: enterpriseActivity && enterpriseActivity.offStatus === 1,
    pinganVipAvaliable: pinganVipActivity && pinganVipActivity.offStatus === 1,
  }
}


function checkURL (sURL) {
  let canAgree = false;
  const orig = /^https?:\/\/[0-9a-zA-Z\.-]+/.exec(sURL)[0];
  whiteUrlArray.forEach(item => {
    if (orig === item) {
      let xieIndex = sURL.indexOf('/', 10);
      let endUrl = sURL.substring(xieIndex, sURL.length);
      canAgree = `${item}${endUrl}`;
      return;
    }
  })
  return canAgree;
}

// 未登录，跳商城端登录页面
function goLogin () {
    storage.clear();
    let redirect_url = escape(window.location.href); // unescape 解密
    window.location.href = `${mallUrl}/account/login?redirect_url=${redirect_url}`;
}


export default {
  login,
  isLogin,
  logout,
  get,
  getUserInfo,
  getName,
  getAuths,
  checkAuthByKey,
  hasAuth,
  isManager,
  getIdCode,
  mallUrl,
  wholeDomain,
  crmUrl,
  tMallUrl,
  omsUrl,
  setCookieDomin,
  getCookie,
  canComeInOms,
  hasOmsYuanQuAuth,
  hasSupplierAuth,
  addUserInfo,
  downloadUrl,
  bcMallUrl,
  checkActivityAvaliable,
  checkURL,


  goLogin,
  isOurStation,
  ishfjl,
  istflq,
  istfsn,
  istfgc,
  islqemall,
  mgrUrl,
  logisticsUrl
}
