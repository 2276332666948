/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// Dev.ToolManager

const prefix = 'dev';

export default {
    // 只能设置字符串
    [`${prefix}AddValueByKey`]: {
        url: '/dev/addValueByKey',
        type: AJAX_TYPE.GET
    },
    // 获取id对应code
    [`${prefix}Code`]: {
        url: '/dev/code/:id',
        type: AJAX_TYPE.GET
    },
    // 删除缓存信息
    [`${prefix}DeleteValueByKey`]: {
        url: '/dev/deleteValueByKey',
        type: AJAX_TYPE.GET
    },
    // 查询缓存信息 1: get 2：getSet  3: getNoVer 
    [`${prefix}GetValueByKey`]: {
        url: '/dev/getValueByKey',
        type: AJAX_TYPE.GET
    },
    // 获取id对应code
    [`${prefix}Id`]: {
        url: '/dev/id/:code',
        type: AJAX_TYPE.GET
    },
}