/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 账单

const prefix = 'bill';

export default {
    // 账单审批
    [`${prefix}Audit`]: {
        url: '/web/bill/audit',
        type: AJAX_TYPE.POST
    },
    // 账单审批统计
    [`${prefix}AuditCount`]: {
        url: '/web/bill/auditCount',
        type: AJAX_TYPE.POST
    },
    // 账单审批
    [`${prefix}AuditList`]: {
        url: '/web/bill/auditList',
        type: AJAX_TYPE.POST
    },
    // 创建账单
    [`${prefix}Create`]: {
        url: '/web/bill/create',
        type: AJAX_TYPE.POST
    },
    // 账单作废
    [`${prefix}DropBill`]: {
        url: '/web/bill/dropBill',
        type: AJAX_TYPE.POST
    },
    // 账单导出excel
    [`${prefix}Export`]: {
        url: '/web/bill/export',
        type: AJAX_TYPE.POST
    },    
    // 查看账单详情
    [`${prefix}GetById`]: {
        url: '/web/bill/getById',
        type: AJAX_TYPE.GET
    },
    // 获取收款 付款 退款统计信息
    [`${prefix}GetCount`]: {
        url: '/web/bill/getCount',
        type: AJAX_TYPE.POST
    },
    // 账单列表
    [`${prefix}GetList`]: {
        url: '/web/bill/getList',
        type: AJAX_TYPE.POST
    },
    // 开收据
    [`${prefix}Receipt`]: {
        url: '/web/bill/receipt',
        type: AJAX_TYPE.POST
    },
    // 收据打印
    [`${prefix}Receiptdownload`]: {
        url: '/web/bill/receipt/download',
        type: AJAX_TYPE.POST
    },
    // 收据列表
    [`${prefix}Receiptlist`]: {
        url: '/web/bill/receipt/list',
        type: AJAX_TYPE.POST
    },
    // 催缴
    [`${prefix}Remind`]: {
        url: '/web/bill/remind',
        type: AJAX_TYPE.POST
    },
    // 账单变更
    [`${prefix}Update`]: {
        url: '/web/bill/update',
        type: AJAX_TYPE.POST
    },
    // 获取收付款统计信息
    [`${prefix}PaymentCount`]: {
        url: '/web/bill/paymentCount',
        type: AJAX_TYPE.POST
    },
    // 获取收付款统计信息
    [`${prefix}FinanceAuditCount`]: {
        url: '/web/bill/financeAuditCount',
        type: AJAX_TYPE.GET
    },
    // 获取操作日志
    [`${prefix}AuditRecord`]: {
        url: '/web/bill/auditRecord',
        type: AJAX_TYPE.POST
    },
    // 获取操作日志
    [`${prefix}GetAuditFlowList`]: {
        url: '/web/bill/getAuditFlowList',
        type: AJAX_TYPE.POST
    },
}