/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 招商-客户

const prefix = 'customer';

export default {
    // 新增客户
    [`${prefix}Add`]: {
        url: '/web/customer/add',
        type: AJAX_TYPE.POST
    },
    // 客户状态统计
    [`${prefix}CustomerStatusCount`]: {
        url: '/web/customer/customerStatusCount',
        type: AJAX_TYPE.POST
    },
    // 删除客户
    [`${prefix}Delete`]: {
        url: '/web/customer/delete/:encode',
        type: AJAX_TYPE.GET
    },
    // 客户分配跟进
    [`${prefix}Dispatch`]: {
        url: '/web/customer/dispatch',
        type: AJAX_TYPE.POST
    },
    // 客户分配跟进批量
    [`${prefix}DispatchBatch`]: {
        url: '/web/customer/dispatchBatch',
        type: AJAX_TYPE.POST
    },
    // 编辑客户
    [`${prefix}Edit`]: {
        url: '/web/customer/edit',
        type: AJAX_TYPE.POST
    },
    // 查看客户信息
    [`${prefix}Get`]: {
        url: '/web/customer/get/:encode',
        type: AJAX_TYPE.GET
    },
    // 可查看客户集合,支持联想
    [`${prefix}GetCustomerList`]: {
        url: '/web/customer/getCustomerList',
        type: AJAX_TYPE.POST
    },
    // 招商列表过滤条件
    [`${prefix}Init`]: {
        url: '/web/customer/init',
        type: AJAX_TYPE.GET
    },
    // 客户管理列表
    [`${prefix}List`]: {
        url: '/web/customer/list',
        type: AJAX_TYPE.POST
    },
    //新增跟进人
    [`${prefix}GetAddUserList`]: {
        url: 'web/customer/getAddUserList',
        type: AJAX_TYPE.POST
    },    
    //批量分配联系人
    [`${prefix}GetDispatchBatchUserList`]: {
        url: '/web/customer/getDispatchBatchUserList',
        type: AJAX_TYPE.POST
    },
    //分配根基人集合
    [`${prefix}GetDispatchUserList`]: {
        url: '/web/customer/getDispatchUserList',
        type: AJAX_TYPE.POST
    },
    //编辑跟进人集合
    [`${prefix}GetEditUserList`]: {
        url: '/web/customer/getEditUserList',
        type: AJAX_TYPE.POST
    },
}