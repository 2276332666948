/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 供应商

const prefix = 'supplier';

export default {
    // 新增供应商
    [`${prefix}Add`]: {
        url: '/web/supplier/add',
        type: AJAX_TYPE.POST
    },
    // 审批
    [`${prefix}Audit`]: {
        url: '/web/supplier/audit',
        type: AJAX_TYPE.POST
    },
    // 删除供应商
    [`${prefix}Delete`]: {
        url: '/web/supplier/delete/:encode',
        type: AJAX_TYPE.DELETE
    },
    // 修改供应商
    [`${prefix}Edit`]: {
        url: '/web/supplier/edit',
        type: AJAX_TYPE.PUT
    },
    // 供应商编辑初始化
    [`${prefix}EditInit`]: {
        url: '/web/supplier/editInit',
        type: AJAX_TYPE.GET
    },
    // 供应商列表初始化
    [`${prefix}Init`]: {
        url: '/web/supplier/init',
        type: AJAX_TYPE.GET
    },
    // 供应商详情
    [`${prefix}QueryDetail`]: {
        url: '/web/supplier/queryDetail/:encode',
        type: AJAX_TYPE.GET
    },
    // 查询列表
    [`${prefix}QueryList`]: {
        url: '/web/supplier/queryList',
        type: AJAX_TYPE.POST
    },
    // 供应商保存并提交
    [`${prefix}SaveAndSubmit`]: {
        url: '/web/supplier/saveAndSubmit',
        type: AJAX_TYPE.PUT
    },
    // 提交审批
    [`${prefix}Submit`]: {
        url: '/web/supplier/submit/:encode',
        type: AJAX_TYPE.POST
    },
    // 对接人列表
    [`${prefix}UserList`]: {
        url: '/web/supplier/userList',
        type: AJAX_TYPE.GET
    },
    // 获取贷款账户信息
    getMoneyInfo: {
        url: '/web/mgr/supplier/account/info/2',
        type: AJAX_TYPE.GET
    },
    getCashLogs: {
        url: '/web/mgr/supplier/cashout/list',
        type: AJAX_TYPE.POST
    },

    // 获取银行卡信息
    getBankallList: {
        url: '/web/mall/bankcard/bankList',
        type: AJAX_TYPE.GET
    },
    getBankCardList: {
        url: '/web/mall/bankcard/bankCardList',
        type: AJAX_TYPE.GET
    },
    unbindCard: {
        url: '/web/mall/bankcard/unbind',
        type: AJAX_TYPE.POST
    },
    getBranchBankList: {
        url: '/web/mall/bankcard/getBranchBankInfo',
        type: AJAX_TYPE.POST
    },

    // 绑卡个人账户 
    bindCardPersonal: {
        url: '/web/mall/bankcard/bindCard/personal',
        type: AJAX_TYPE.POST
    },
    // 绑卡企业账户
    bindCardEnterprise: {
        url: '/web/mall/bankcard/bindCard/enterprise',
        type: AJAX_TYPE.POST
    },
    // 绑卡个人账户验证
    bindCardIdentifyPersonal: {
        url: '/web/mall/bankcard/bindCardIdentify/personal',
        type: AJAX_TYPE.POST
    },
    // 绑卡企业账户验证
    bindCardIdentifyEnterprise: {
        url: '/web/mall/bankcard/bindCardIdentify/enterprise',
        type: AJAX_TYPE.POST
    },
    // 回显账户
    feedbackAccount: {
        url: '/web/mall/bankcard/feedbackAccount',
        type: AJAX_TYPE.GET
    },
    changeMobile: {
        url: '/web/mall/bankcard/changeMobile',
        type: AJAX_TYPE.POST
    },
    changeMobileCheck: {
        url: '/web/mall/bankcard/changeMobileCheck',
        type: AJAX_TYPE.POST
    },


    // 提现支付短信
    cashoutSendSms: {
        url: '/web/mgr/supplier/cashout/sendSms',
        type: AJAX_TYPE.POST
    },
    // 提现
    cashoutAdd: {
        url: '/web/mgr/supplier/cashout/add',
        type: AJAX_TYPE.POST
    },
    // 线上收支明细
    getOrderStats: {
        url: '/web/mgr/supplier/account/orderStats',
        type: AJAX_TYPE.POST
    },
    // 线上收支明细
    getOrderList: {
        url: '/web/mgr/supplier/account/orderList',
        type: AJAX_TYPE.POST
    },
    // 线下收支明细
    getOffStats: {
        url: '/web/mgr/supplier/account/offlineOrderStats',
        type: AJAX_TYPE.POST
    },
    // 线下收支明细
    getOffList: {
        url: '/web/mgr/supplier/account/offlineOrderList',
        type: AJAX_TYPE.POST
    },

      // 判断是不是 供应商
      checkIsSupplier: {
        url: '/web/mall/user/isSupplier',
        type: AJAX_TYPE.GET
    }
}