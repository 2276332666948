/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// project-stats-controller

const prefix = 'projectstats';

export default {
    // 项目-承租企业画像
    [`${prefix}Customer`]: {
        url: '/web/project/stats/customer/:projectEncode',
        type: AJAX_TYPE.GET
    },
    // 项目空间-项目列表-地理视图
    [`${prefix}Geo`]: {
        url: '/web/project/stats/geo',
        type: AJAX_TYPE.GET
    },
    // 项目空间-项目概况-实时信息
    [`${prefix}Info`]: {
        url: '/web/project/stats/info/:projectEncode',
        type: AJAX_TYPE.GET
    },
    // 项目空间-销售面积-排名
    [`${prefix}Rankarea`]: {
        url: '/web/project/stats/rank/area/',
        type: AJAX_TYPE.GET
    },
    // 项目空间-销售-均价-排名
    [`${prefix}Rankprice`]: {
        url: '/web/project/stats/rank/price',
        type: AJAX_TYPE.GET
    },
    // 项目空间-销售收入-排名
    [`${prefix}Ranksale`]: {
        url: '/web/project/stats/rank/sale/',
        type: AJAX_TYPE.GET
    },
    // 项目空间-出租率
    [`${prefix}RentalRate`]: {
        url: '/web/project/stats/rentalRate/:projectEncode',
        type: AJAX_TYPE.GET
    },
}