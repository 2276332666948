/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 招商-意向

const prefix = 'intention';

export default {
    // 新增意向
    [`${prefix}Add`]: {
        url: '/web/intention/add',
        type: AJAX_TYPE.POST
    },
    // 添加意向跟进
    [`${prefix}AddFollowup`]: {
        url: '/web/intention/addFollowup',
        type: AJAX_TYPE.POST
    },
    // 删除意向
    [`${prefix}Delete`]: {
        url: '/web/intention/delete/:encode',
        type: AJAX_TYPE.POST
    },
    // 意向分配
    [`${prefix}Dispatch`]: {
        url: '/web/intention/dispatch',
        type: AJAX_TYPE.POST
    },
    // 意向分配批量
    [`${prefix}DispatchBatch`]: {
        url: '/web/intention/dispatchBatch',
        type: AJAX_TYPE.POST
    },
    // 编辑意向
    [`${prefix}Edit`]: {
        url: '/web/intention/edit',
        type: AJAX_TYPE.POST
    },
    // 查看意向信息
    [`${prefix}Get`]: {
        url: '/web/intention/get/:encode',
        type: AJAX_TYPE.GET
    },
    // 意向列表过滤条件
    [`${prefix}Init`]: {
        url: '/web/intention/init',
        type: AJAX_TYPE.GET
    },
    // 意向管理列表
    [`${prefix}List`]: {
        url: '/web/intention/list',
        type: AJAX_TYPE.POST
    },
    // 意向统计 
    [`${prefix}StatusCount`]: {
        url: '/web/intention/statusCount',
        type: AJAX_TYPE.POST
    },
    //批量分配联系人
    [`${prefix}GetDispatchBatchUserList`]: {
        url: '/web/intention/getDispatchBatchUserList',
        type: AJAX_TYPE.POST
    },
    //分配根基人集合
    [`${prefix}GetDispatchUserList`]: {
        url: '/web/intention/getDispatchUserList',
        type: AJAX_TYPE.POST
    },
    //新增跟进人集合
    [`${prefix}GetAddUserList`]: {
        url: '/web/intention/getAddUserList',
        type: AJAX_TYPE.POST
    },
    //编辑跟进人集合
    [`${prefix}GetEditUserList`]: {
        url: '/web/intention/getEditUserList',
        type: AJAX_TYPE.POST
    },
}