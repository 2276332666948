/* eslint-disable */
import { AJAX_TYPE } from '../config';

const prefix = 'trans';

const baseURL = ''
// process.env.NODE_ENV == 'development' ? '' : 'http://oms-mall.st1.idf10.com.cn/';

export default {
	[`${prefix}QueryList`]: {
		url: baseURL + '/web/mgr/trans/queryList',
		type: AJAX_TYPE.POST
	},
	[`${prefix}Detail`]: {
		url: baseURL + '/web/mgr/trans/detail',
		type: AJAX_TYPE.POST
	}
};
