/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

const prefix = 'elec';

export default {
    //签约列表
    [`${prefix}List`]: {
        url: '/web/mgr/contract/getSignContractList',
        type: AJAX_TYPE.GET
    },
    //查看合同  
    [`${prefix}ViewContract`]: {
        url: '/web/mgr/contract/viewContract',
        type: AJAX_TYPE.POST
    },
    //发起线下签约 
    [`${prefix}SignOffline`]: {
        url: '/web/mgr/contract/signOffline',
        type: AJAX_TYPE.POST
    },
    // CA绑定
    [`${prefix}Cabind`]: {
        url: '/web/crm/enterprise/bindCa',
        type: AJAX_TYPE.POST
    },
    // ca盖章保存
    [`${prefix}CaSave`]: {
        url: '/web/mgr/seal/supply',
        type: AJAX_TYPE.POST
    },
    //签约详情  
    [`${prefix}SignDetail`]: {
        url: '/web/mgr/contract/getSignDetail',
        type: AJAX_TYPE.GET
    },
    //信息确认，生成合同  
    [`${prefix}ComfirmSignInfo`]: {
        url: '/web/mgr/contract/comfirmSignInfo',
        type: AJAX_TYPE.POST
    },
    //线上预览合同   
    [`${prefix}PreviewContract`]: {
        url: '/web/mgr/contract/previewContract',
        type: AJAX_TYPE.GET
    },  
    //发起签约请求
    [`${prefix}CreateContract`]: {
        url: '/web/mgr/contract/createContract',
        type: AJAX_TYPE.POST
    }
}