/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 审批模板

const prefix = 'flowTemplate';

export default {
    // 模板新增
    [`${prefix}Add`]: {
        url: '/web/flowTemplate/add',
        type: AJAX_TYPE.POST
    },
    // 模板编辑初始化
    [`${prefix}AddInit`]: {
        url: '/web/flowTemplate/addInit',
        type: AJAX_TYPE.GET
    },
    // 模板配置初始化
    [`${prefix}ConfigInit`]: {
        url: '/web/flowTemplate/configInit',
        type: AJAX_TYPE.GET
    },
    // 模板配置列表
    [`${prefix}ConfigList`]: {
        url: '/web/flowTemplate/configList',
        type: AJAX_TYPE.GET
    },
    // 配置
    [`${prefix}Configuration`]: {
        url: '/web/flowTemplate/configuration',
        type: AJAX_TYPE.GET
    },
    // 模板复制
    [`${prefix}Copy`]: {
        url: '/web/flowTemplate/copy',
        type: AJAX_TYPE.POST
    },
    // 模板删除
    [`${prefix}Delete`]: {
        url: '/web/flowTemplate/delete',
        type: AJAX_TYPE.GET
    },
    // 模板详情
    [`${prefix}Get`]: {
        url: '/web/flowTemplate/get',
        type: AJAX_TYPE.GET
    },
    // 模板列表初始化
    [`${prefix}Init`]: {
        url: '/web/flowTemplate/init',
        type: AJAX_TYPE.GET
    },
    // 模板列表
    [`${prefix}List`]: {
        url: '/web/flowTemplate/list',
        type: AJAX_TYPE.POST
    },
    // 下线
    [`${prefix}Offline`]: {
        url: '/web/flowTemplate/offline',
        type: AJAX_TYPE.GET
    },
    // 上线
    [`${prefix}Online`]: {
        url: '/web/flowTemplate/online',
        type: AJAX_TYPE.GET
    },
    // 查询模板配置
    [`${prefix}QueryConfig`]: {
        url: '/web/flowTemplate/queryConfig',
        type: AJAX_TYPE.POST
    },
    // 模板修改
    [`${prefix}Update`]: {
        url: '/web/flowTemplate/update',
        type: AJAX_TYPE.POST
    },
    // 获取审批人
    [`${prefix}Users`]: {
        url: '/web/flowTemplate/users',
        type: AJAX_TYPE.POST
    },
}