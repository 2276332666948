/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 售后

const prefix = 'area';

export default {
    // 售后列表
    // /web/mgr/afterSales/getAfterSaleList
    [`afterSaleList`]: {
        url: '/web/mgr/afterSales/getAfterSaleList',
        type: AJAX_TYPE.POST
    },
    // 售后详情
    // /web/mgr/afterSales/getAfterDetailById
    [`afterSalesGetAfterDetailById`]: {
        url: '/web/mgr/afterSales/getAfterDetailById',
        type: AJAX_TYPE.POST
    },
    // 协商记录
    // /web/mgr/afterSales/getAfterServiceRecords
    [`afterSalesGetAfterServiceRecords`]: {
        url: '/web/mgr/afterSales/getAfterServiceRecords',
        type: AJAX_TYPE.POST
    },
    // 同意退款
    // /web/mgr/refund/agreeRefund
    [`refundAgreeRefund`]: {
        url: '/web/mgr/refund/agreeRefund',
        type: AJAX_TYPE.POST
    },
    // 拒绝退款
    // /web/mgr/refund/rejectRefund
    [`refundRejectRefund`]: {
        url: '/web/mgr/refund/rejectRefund',
        type: AJAX_TYPE.POST
    },
    // 已完成退款
    // /web/mgr/refund/completeRefund
    [`refundCompleteRefund`]: {
        url: '/web/mgr/refund/completeRefund',
        type: AJAX_TYPE.POST
    },

    // 查看退款详情
    // /web/mgr/refund/getRefundInfo
    [`refundGetRefundInfo`]: {
        url: '/web/mgr/refund/getRefundInfo',
        type: AJAX_TYPE.POST
    },

    // 商家已收退货
    // /web/mgr/goodsReturn/receivedGoodsReturn
    [`receivedGoodsReturn`]: {
        url: '/web/mgr/goodsReturn/receivedGoodsReturn',
        type: AJAX_TYPE.POST
    },

    // 商家同意退货
    // /web/mgr/goodsReturn/agreeGoodsReturn
    [`agreeGoodsReturn`]: {
        url: '/web/mgr/goodsReturn/agreeGoodsReturn',
        type: AJAX_TYPE.POST
    },

    // 商家拒绝退货
    // /web/mgr/goodsReturn/rejectGoodsReturn
    [`rejectGoodsReturn`]: {
        url: '/web/mgr/goodsReturn/rejectGoodsReturn',
        type: AJAX_TYPE.POST
    },

}