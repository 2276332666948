/* eslint-disable */
import { AJAX_TYPE } from "../config";

const prefix = "refund";
const baseURL = "/web/mgr/refund";

export default {
  // 线上-发送退款验证码
  [`${prefix}OnlineSms`]: {
    url: baseURL + "/online/sms",
    type: AJAX_TYPE.POST
  },

  // 线上-商家同意退款
  [`${prefix}OnlineAgree`]: {
    url: baseURL + "/online/agreeRefund",
    type: AJAX_TYPE.POST
  },

  // 线上-商家拒绝退款
  [`${prefix}OnlineReject`]: {
    url: baseURL + `/online/rejectRefund`,
    type: AJAX_TYPE.POST
  },

  // 线上-商家重新同意退款
  [`${prefix}OnlineRepeat`]: {
    url: baseURL + "/online/repeatAgreeRefund",
    type: AJAX_TYPE.POST
  },

  // 线上-查看退款详情
  [`${prefix}RefundOnline`]: {
    url: baseURL + "/onlineRefundInfo",
    type: AJAX_TYPE.POST
  },

  // 线上-商家再次发起退款
  [`${prefix}AgreeRefundAgain`]: {
    url: baseURL + "/online/agreeRefundAgain",
    type: AJAX_TYPE.POST
  },

  
};
