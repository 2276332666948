/* eslint-disable */

let mallUrlForHttp = ""; // 为了防止跨域，用于请求数据的mall域名
let crmUrlForHttp = "";



export default {
  mallUrlForHttp,
  crmUrlForHttp
}
