/* eslint-disable */
import { AJAX_TYPE } from '../config';

const baseURL = ''
// process.env.NODE_ENV == "development" ? '' : 'http://oms-mall.st1.idf10.com.cn/'
const prefix = 'category';

export default {
	[`${prefix}QueryCategoryTree`]: {
		url: baseURL + '/web/mgr/category/queryCategoryTree',
		type: AJAX_TYPE.GET
	},
	[`${prefix}RecentUse`]: {
		url: baseURL + '/web/mgr/category/recent/use',
		type: AJAX_TYPE.POST
	},
	[`${prefix}Use`]: {
		url: baseURL + '/web/mgr/category/use',
		type: AJAX_TYPE.POST
	},
	[`${prefix}Query`]: {
		url: baseURL + '/web/mgr/category/query',
		type: AJAX_TYPE.POST
	},
	[`${prefix}QueryOneAndTwoLevel`]: {
		url: '/api/isx/v1/category/queryOneAndTwoLevel',
		type: AJAX_TYPE.POST
	},


	
};
