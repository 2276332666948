/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 运费模板

const prefix = 'delivery_template';

export default {
    // 录入模板信息
    [`${prefix}Add`]: {
        url: '/web/mgr/trans/add',
        type: AJAX_TYPE.POST

    },

    // 删除模板信息
    [`${prefix}Delete`]: {
        url: '/web/mgr/trans/delete',
        type: AJAX_TYPE.POST
    },

    // 更新模板信息
    [`${prefix}Update`]: {
        url: '/web/mgr/trans/update',
        type: AJAX_TYPE.POST
    },

    //  模板信息
    [`${prefix}Detail`]: {
        url: '/web/mgr/trans/detail',
        type: AJAX_TYPE.POST
    },
       //  复制一个模板信息
       [`${prefix}Copy`]: {
        url: '/web/mgr/trans/copy',
        type: AJAX_TYPE.POST
    },

    //  模板列表
    [`${prefix}List`]: {
        url: '/web/mgr/trans/queryList',
        type: AJAX_TYPE.POST
    },
 

    // 查询当前用户名下的shopCode。目前为单店铺，需要从后台查询所属店铺。多店铺模式下，这里就没有意义
    [`${prefix}ShopCode`]: {
        url: '/web/mgr/shop/getByCurrentuser',
        type: AJAX_TYPE.GET
    },

    // 删除免邮条件中的某一个设置项
    [`${prefix}DeleteFree`]: {
        url: '/web/mgr/trans/delete/free',
        type: AJAX_TYPE.POST
    },
    // 删除运费设置中的某一个设置项
    [`${prefix}DeleteFee`]: {
        url: '/web/mgr/trans/delete/fee',
        type: AJAX_TYPE.POST
    },

    // 删除运费设置中的某一个设置项
    [`${prefix}AllArea`]: {
        url: '/web/mgr/area/provinceChildren',
        type: AJAX_TYPE.GET
    }



}