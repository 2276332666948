/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 决策

const prefix = 'decision';

export default {
    // 新增决策
    [`${prefix}Add`]: {
        url: '/web/decision/add',
        type: AJAX_TYPE.POST
    },
    // 根据ID删除决策
    [`${prefix}/web/DeleteById`]: {
        url: '/web/decision/deleteById/:decisionId',
        type: AJAX_TYPE.GET
    },
    // 根据key删除决策
    [`${prefix}DeleteByKey`]: {
        url: '/web/decision/deleteByKey/:projectEncode',
        type: AJAX_TYPE.GET
    },
    // 编辑决策
    [`${prefix}Edit`]: {
        url: '/web/decision/edit',
        type: AJAX_TYPE.POST
    },
    // 查看项目下的所有决策
    [`${prefix}ByProjectEncode`]: {
        url: '/web/decision/getByKeyByProjectId/:projectEncode',
        type: AJAX_TYPE.GET
    },
    // 获取自定义决策的项目
    [`${prefix}DefaultSettingProjects`]: {
        url: '/web/decision/getCustomizeType',
        type: AJAX_TYPE.POST
    },
    // 查看决策信息
    [`${prefix}GetByKey`]: {
        url: '/web/decision/getByKey/:projectId',
        type: AJAX_TYPE.GET
    },
}