/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 资产管理
// 地区

const prefix = 'assets';

export default {
    // 初始化（资产配置-资产类型）
    [`${prefix}Init`]: {
        url: '/web/assets/init',
        type: AJAX_TYPE.GET
    },
    // 资产子类型
    [`${prefix}SubType`]: {
        url: '/web/assets/listAssetSubtype/:encode',
        type: AJAX_TYPE.GET
    },
    // 根据资产类型获取资产型号列表
    [`${prefix}ModelList`]: {
        url: '/web/assets/listAssetModel/:encode',
        type: AJAX_TYPE.GET
    },
    // 获取归属系统
    [`${prefix}GetSystem`]: {
        url: '/web/sys/config/system/list',
        type: AJAX_TYPE.GET
    },
    // 获取维护人列表
    [`${prefix}GetUserList`]: {
        url: '/web/user/getList',
        type: AJAX_TYPE.POST
    },
    // 添加资产
    [`${prefix}Add`]: {
        url: '/web/assets/create',
        type: AJAX_TYPE.POST
    },
    // 获取资产列表
    [`${prefix}List`]: {
        url: '/web/assets/list',
        type: AJAX_TYPE.GET
    },
    // 资产详情
    [`${prefix}Detail`]: {
        url: '/web/assets/detail/:encode',
        type: AJAX_TYPE.GET
    },
    // 资产领用
    [`${prefix}Receive`]: {
        url: '/web/assets/receive',
        type: AJAX_TYPE.POST
    },
    // 资产退库
    [`${prefix}Refund`]: {
        url: '/web/assets/refund/:assetsId',
        type: AJAX_TYPE.POST
    },
    // 屋内
    // 删除资产型号
    [`${prefix}DeleteRoomEquipmentModel`]: {
        url: '/web/sys/config/deleteRoomEquipmentModel',
        type: AJAX_TYPE.POST
    },
    // 删除资产类别
    [`${prefix}DeleteRoomEquipment`]: {
        url: '/web/sys/config/deleteRoomEquipment',
        type: AJAX_TYPE.POST
    },
    // 新增类型
    [`${prefix}AddRoomEquipment`]: {
        url: '/web/sys/config/addRoomEquipment',
        type: AJAX_TYPE.POST
    },
    // 编辑类型
    [`${prefix}EditRoomEquipment`]: {
        url: '/web/sys/config/editRoomEquipment',
        type: AJAX_TYPE.POST
    },
    // 新增型号
    [`${prefix}AddRoomEquipmentModel`]: {
        url: '/web/sys/config/addRoomEquipmentModel',
        type: AJAX_TYPE.POST
    },
    // 编辑型号
    [`${prefix}EditRoomEquipmentModel`]: {
        url: '/web/sys/config/editRoomEquipmentModel',
        type: AJAX_TYPE.POST
    },

    // 公共
     // 删除资产型号
     [`${prefix}DeletePublicEquipmentModel`]: {
        url: '/web/sys/config/deletePublicEquipmentModel',
        type: AJAX_TYPE.POST
    },
    // 删除资产类别
    [`${prefix}DeletePublicEquipment`]: {
        url: '/web/sys/config/deletePublicEquipment',
        type: AJAX_TYPE.POST
    },
    // 新增类型
    [`${prefix}AddPublicEquipment`]: {
        url: '/web/sys/config/addPublicEquipment',
        type: AJAX_TYPE.POST
    },
    // 编辑类型
    [`${prefix}EditPublicEquipment`]: {
        url: '/web/sys/config/editPublicEquipment',
        type: AJAX_TYPE.POST
    },
    // 新增型号
    [`${prefix}AddPublicEquipmentModel`]: {
        url: '/web/sys/config/addPublicEquipmentModel',
        type: AJAX_TYPE.POST
    },
    // 编辑型号
    [`${prefix}EditPublicEquipmentModel`]: {
        url: '/web/sys/config/editPublicEquipmentModel',
        type: AJAX_TYPE.POST
    }
}
