/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// user service

const prefix = 'help';

export default {
    // 获取首页信息
    [`${prefix}GetHelpList`]: {
        url: '/web/helpcenter/getHelpList',
        type: AJAX_TYPE.GET
    },
    // 问答列表侧栏
    [`${prefix}GetMessageList`]: {
        url: '/web/helpcenter/getMessageList',
        type: AJAX_TYPE.POST
    },
    // 问答列表
    [`${prefix}GetHelpMessageListByPage`]: {
        url: '/web/helpcenter/getHelpMessageListByPage',
        type: AJAX_TYPE.POST
    },
    // 问答详情
    [`${prefix}GetMessageDetail`]: {
        url: '/web/helpcenter/getMessageDetail',
        type: AJAX_TYPE.GET
    },
    // 视频列表
    [`${prefix}GetVideoList`]: {
        url: '/web/helpcenter/getVideoList',
        type: AJAX_TYPE.POST
    },
    // 视频详情
    [`${prefix}GetVideoDetail`]: {
        url: '/web/helpcenter/getVideoDetail',
        type: AJAX_TYPE.GET
    },
    // 点击视频播放 触发视频播放量 +1
    [`${prefix}AddVideoSeeCount`]: {
        url: '/web/helpcenter/addVideoSeeCount',
        type: AJAX_TYPE.POST
    },
    // 检索列表
    [`${prefix}GetHelpSearchResultListByPage`]: {
        url: '/web/helpcenter/getHelpSearchResultListByPage',
        type: AJAX_TYPE.POST
    },
}