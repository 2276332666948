/* eslint-disable */
import { AJAX_TYPE } from '../config';

const prefix = 'overview';

const baseURL = ''
// process.env.NODE_ENV == 'development' ? '' : 'http://oms-mall.st1.idf10.com.cn';

export default {
	// 近7天商品浏览量
	[`${prefix}PageView7Item`]: {
		url: '/web/mgr/overview/pageView7Item',
		type: AJAX_TYPE.POST
    },
    //近7天单个商品浏览排行量
    [`${prefix}PageView7ItemRank`]: {
		url: '/web/mgr/overview/pageView7ItemRank',
		type: AJAX_TYPE.POST
	},
    //近7天新增商品收藏量
    [`${prefix}Collect7Item`]: {
		url: '/web/mgr/overview/collect7Item',
		type: AJAX_TYPE.POST
    },
    //近7天单个商品收藏排行
    [`${prefix}Collect7ItemRank`]: {
		url: '/web/mgr/overview/collect7ItemRank',
		type: AJAX_TYPE.POST
    },
    //近7天单个商品购买排行
    [`${prefix}Buy7ItemRank`]: {
		url: '/web/mgr/overview/buy7ItemRank',
		type: AJAX_TYPE.POST
    },
    //近7天单个商品综合评分排行
    [`${prefix}Comment7ItemRank`]: {
		url: '/web/mgr/overview/comment7ItemRank',
		type: AJAX_TYPE.POST
    },
    ///当前上架商品数量
    [`${prefix}PutOnShelveItem`]: {
		url: '/web/mgr/overview/putOnShelveItem',
		type: AJAX_TYPE.POST
    },
    ///当前待上架商品数量
    [`${prefix}WaitingShelveItem`]: {
		url: '/web/mgr/overview/waitingShelveItem',
		type: AJAX_TYPE.POST
    },
    ///商品累计收藏量
    [`${prefix}CollectItem`]: {
		url: '/web/mgr/overview/collectItem',
		type: AJAX_TYPE.POST
    },
    ///当前待上架商品数量
    [`${prefix}PageViewItem`]: {
		url: '/web/mgr/overview/pageViewItem',
		type: AJAX_TYPE.POST
    },
};
