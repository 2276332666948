/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// Dev.ToolManager

const prefix = 'draft';

export default {
    // 删除草稿
    [`${prefix}Delete`]: {
        url: '/web/mall/draft/:bizType',
        type: AJAX_TYPE.DELETE
    },
    // 获取草稿
    [`${prefix}Query`]: {
        url: '/web/mall/draft/:bizType',
        type: AJAX_TYPE.GET
    },
    // 保存草稿
    [`${prefix}Save`]: {
        url: '/web/mall/draft/save',
        type: AJAX_TYPE.POST
    },
}