/* eslint-disable */
/**
 * 投标管理
 */

import { AJAX_TYPE } from "../config";

const prefix = "deposit";
const baseURL = "/web/mgr/deposit";

export default {
  // 生成充值订单
  [`${prefix}CreateOrder`]: {
    url: baseURL + "/create/order",
    type: AJAX_TYPE.POST
  },

  // 账户金额
  [`${prefix}Detail`]: {
    url: baseURL + "/detail",
    type: AJAX_TYPE.GET
  },

  // 充值记录列表
  [`${prefix}InList`]: {
    url: baseURL + "/in/list",
    type: AJAX_TYPE.POST
  },

};
