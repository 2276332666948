/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 合同报表

const prefix = 'statementcontract';

export default {
    // 合同报表
    [`${prefix}ContractReport`]: {
        url: '/statement/contract/contractReport',
        type: AJAX_TYPE.POST
    },
    // 合同报表
    [`${prefix}ContractReportExport`]: {
        url: '/statement/contract/contractReportExport',
        type: AJAX_TYPE.POST
    },
}