/* eslint-disable */
/**
 * 商办权限梳理表：http://pms.ipo.com/pages/viewpage.action?pageId=138936933
 * 权限id：http://pms.ipo.com/pages/viewpage.action?pageId=138958258
 * 每个权限key对应相应权限的id值，如：设置中心("设置中心",9903L,99L,Boolean.FALSE,null)中第一个数字9903为设置中心的权限id，故SETTING: 9903
 */
export const AUTH_KEYS = Object.freeze({
    PROJECT: 9901,
    PROJECT_All:990101,
    PROJECT_NEW:990102,
    PROJECT_NEW_REGION:99010201,
    PROJECT_LIST:990103,
    PROJECT_OVERVIEW:99010301,
    PROJECT_EDIT_ENTRANCE:9901030101,
    PROJECT_DELETE:9901030102,
    PROJECT_EDIT:9901030103,
    PROJECT_EAM:99010302,
    PROJECT_NEW_REGION_ADD:9901030201,
    PROJECT_REGION_INFO:99010303,
    PROJECT_REGION_OPERATE:99010304,
    PROJECT_REGION_OPERATE_SIGN:9901030401,
    PROJECT_REGION_OPERATE_EDIT:9901030402,
    PROJECT_REGION_OPERATE_MERGE:9901030403,
    PROJECT_REGION_OPERATE_DIVISION:9901030404,
    PROJECT_REGION_OPERATE_DELETE:9901030405,
    PROJECT_REGION_OPERATE_CHECKCONTRACT:9901030406,
    PROJECT_REGION_OPERATE_AGAINSIGN:9901030407,
    PROJECT_REGION_OPERATE_TUIZU:9901030408,
    PROJECT_REGION_OPERATE_HISTORYCONTRACT:9901030409,
    PROJECT_REGION_OPERATE_DECORATION:9901030410,
    PROJECT_REGION_OPERATE_RENTSTATUS:9901030411,
    PROJECT_REGION_OPERATE_ADDCUSTOMER:9901030412,
    OPERATE: 9902,
    MYJOB: 9907, // 我的工作
    // MYJOB_UPCOMING: 'MYJOB_UPCOMING',
    // MYJOB_SCHEDULE: 'MYJOB_SCHEDULE',
    OPERATE_INVITATION: 990202,
    OPERATE_INVITATION_CLIENT: 99020201,
    OPERATE_INVITATION_CLIENT_ADD: 9902020106,
    OPERATE_INVITATION_CLIENT_EDIT: 9902020102,
    OPERATE_INVITATION_CLIENT_EDIT_ALL:990202010201,
    OPERATE_INVITATION_CLIENT_EDIT_SELF:990202010202,
    OPERATE_INVITATION_CLIENT_DISPATCH: 9902020105,
    OPERATE_INVITATION_CLIENT_DISPATCH_ALL: 990202010501,
    OPERATE_INVITATION_CLIENT_DISPATCH_SELF: 990202010502,
    OPERATE_INVITATION_CLIENT_DISPATCH_BATCH: 9902020107,
    OPERATE_INVITATION_CLIENT_DISPATCH_BATCH_ALL: 990202010701,
    OPERATE_INVITATION_CLIENT_DISPATCH_BATCH_SELF: 990202010702,
    OPERATE_INVITATION_INTENTION: 99020202,
    OPERATE_INVITATION_INTENTION_DETAIL: 9902020201,
    OPERATE_INVITATION_INTENTION_ADD: 9902020207,
    OPERATE_INVITATION_INTENTION_EDIT: 9902020202,
    OPERATE_INVITATION_INTENTION_EDIT_ALL:990202020201,
    OPERATE_INVITATION_INTENTION_EDIT_SELF:990202020202,
    OPERATE_INVITATION_INTENTION_FOLLOW: 9902020204,
    OPERATE_INVITATION_INTENTION_FOLLOW_ALL: 990202020401,
    OPERATE_INVITATION_INTENTION_FOLLOW_SELF: 990202020402,
    OPERATE_INVITATION_INTENTION_DISPATCH: 9902020205,
    OPERATE_INVITATION_INTENTION_DISPATCH_ALL:990202020501,
    OPERATE_INVITATION_INTENTION_DISPATCH_SELF:990202020502,
    OPERATE_INVITATION_INTENTION_DISPATCH_BATCH: 9902020206,
    OPERATE_INVITATION_INTENTION_DISPATCH_BATCH_ALL: 990202020601,
    OPERATE_INVITATION_INTENTION_DISPATCH_BATCH_SELF: 990202020602,
    OPERATE_SERVICE: 990204,
    OPERATE_SERVICE_LOGISTICS: 99020401,
    OPERATE_SERVICE_LOGISTICS_MAINTAIN: 9902040101,
    OPERATE_SERVICE_LOGISTICS_MAINTAIN_ADDORDER: 990204010102,
    OPERATE_SERVICE_LOGISTICS_MAINTAIN_SENDORDER: 990204010103,
    OPERATE_SERVICE_LOGISTICS_MAINTAIN_ACCEPTANCEORDER: 990204010105,
    OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER: 990204010106,
    OPERATE_SERVICE_LOGISTICS_MAINTAIN_CANCELORDER: 990204010107,
    OPERATE_SERVICE_LOGISTICS_COMPLAINT: 9902040102,
    OPERATE_SERVICE_LOGISTICS_COMPLAINT_ADDORDER: 990204010202,
    OPERATE_SERVICE_LOGISTICS_COMPLAINT_SENDORDER: 990204010203,
    OPERATE_SERVICE_LOGISTICS_COMPLAINT_ACCEPTANCEORDER: 990204010206,
    OPERATE_SERVICE_LOGISTICS_COMPLAINT_CANCELORDER: 990204010207,
    OPERATE_SERVICE_SUPPLIER_SUPPLIERLISTS: 9902040301,
    OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT: 9902040302,
    OPERATE_CONTRACT: 990201,
    OPERATE_CONTRACT_ADD: 9902010107,
    SETTING: 9903,
    SETTING_ORGANIZATION: 990301,
    SETTING_ORGANIZATION_DEPARTMENT: 99030101,
    SETTING_ORGANIZATION_DEPARTMENT_LIST: 9903010101,
    SETTING_ORGANIZATION_DEPARTMENT_NEW: 9903010102,
    SETTING_ORGANIZATION_DEPARTMENT_MODIFY: 9903010103,    
    SETTING_ORGANIZATION_DEPARTMENT_DELETE: 9903010104,
    SETTING_ORGANIZATION_ROLE: 99030102,
    SETTING_ORGANIZATION_ROLE_LIST: 9903010201,
    SETTING_ORGANIZATION_ROLE_NEW: 9903010202,
    SETTING_ORGANIZATION_ROLE_MODIFY: 9903010203,
    SETTING_ORGANIZATION_ROLE_ASSIGN: 9903010204,
    SETTING_ORGANIZATION_ROLE_DELETE: 9903010205,
    SETTING_ORGANIZATION_STAFF: 99030103,
    SETTING_ORGANIZATION_STAFF_LIST: 9903010301,
    SETTING_ORGANIZATION_STAFF_NEW: 9903010302,
    SETTING_ORGANIZATION_STAFF_MODIFY: 9903010303,
    SETTING_ORGANIZATION_STAFF_DELETE: 9903010304,
    SETTING_ORGANIZATION_STAFF_DISPATCH_BATCH: 9903010305,
    SETTING_APPROVE: 990304,
    SETTING_APPROVE_MANAGE: 99030401,
    SETTING_APPROVE_CONFIG: 99030402,
    SETTING_TEMPLATE:990302,
    SETTING_TEMPLATE_CONTRACT:99030201,
    SETTING_TEMPLATE_CONTRACT_LOOK:9903020101,
    SETTING_TEMPLATE_CONTRACT_NEW:9903020102,
    SETTING_TEMPLATE_CONTRACT_MODIFY:9903020103,
    SETTING_TEMPLATE_CONTRACT_DELETE:9903020104,
    SETTING_TEMPLATE_CONTRACT_COPY:9903020105,
    SETTING_TEMPLATE_CONTRACT_PREVIEW:9903020106,
    SETTING_TEMPLATE_CONTRACT_DOWNLOAD:9903020107,
    SETTING_TEMPLATE_RECEIPT:99030202,
    SETTING_TEMPLATE_RECEIPT_LOOK:9903020201,
    SETTING_TEMPLATE_RECEIPT_NEW:9903020202,
    SETTING_TEMPLATE_RECEIPT_MODIFY:9903020203,
    SETTING_TEMPLATE_RECEIPT_DELETE:9903020204,
    SETTING_TEMPLATE_RECEIPT_COPY:9903020205,
    SETTING_TEMPLATE_RECEIPT_PREVIEW:9903020206,
    SETTING_TEMPLATE_RECEIPT_DOWNLOAD:9903020207,
    SETTING_SERVICE: 990303,
    SETTING_SERVICE_INDOORFACILITIES: 99030301,
    SETTING_SERVICE_INDOORFACILITIES_ADD: 9903030102,
    SETTING_SERVICE_INDOORFACILITIES_EDIT: 9903030103,
    SETTING_SERVICE_INDOORFACILITIES_DETELE: 9903030104,
    SETTING_SERVICE_PUBLICFACILITIES: 99030302,
    SETTING_SERVICE_PUBLICFACILITIES_ADD: 9903030202,
    SETTING_SERVICE_PUBLICFACILITIES_EDIT: 9903030203,
    SETTING_SERVICE_PUBLICFACILITIES_DETELE: 9903030204,
    SETTING_SERVICE_CONSUMABLES: 99030303,
    SETTING_SERVICE_CONSUMABLES_ADD: 9903030302,
    SETTING_SERVICE_CONSUMABLES_EDIT: 9903030303,
    SETTING_SERVICE_CONSUMABLES_DETELE: 9903030304,
    SETTING_SERVICE_DECISION_PROJECT: 990305,
    SETTING_SERVICE_DECISION_CONTRACT: 990306,
    OPERATE_REPORTFORM: 990205, // 园区报表中心
    OPERATE_REPORTFORM_PROJECTREPORT: 99020501,
    OPERATE_REPORTFORM_REGIONALREPORT: 99020502,
    OPERATE_REPORTFORM_CONTRACTREPORT: 99020503,
    OPERATE_REPORTFORM_INCOMEEXPENSEREPORT: 99020504,  
    OPERATE_REPORTFORM_INCOMEEXPENSE_EXPORT: 9902030303,
    OPERATE_REPORTFORM_RESOURCEREPORT: 99020505,
    OPERATE_REPORTFORM_RESOURCEREPORT_EXPORT: 9902030303,
    OPERATE_REPORTFORM_ARREARSDETAILEDREPORT: 99020506,

    SUPPLIER_AUTH_REPORTFORM: 990607, // 供应商报表中心
    OPERATE_REPORTFORM_SPUREPORT: 99020507, // 商品SPU报表
    OPERATE_REPORTFORM_SKUREPORT: 99020508,// 商品SKU报表
    OPERATE_REPORTFORM_ORDER_REPORT: 99020509,// 订单报表

    OPERATE_REPORTFORM_SPUREPORT_EXPORT: 99020510, // SPU报表导出
    OPERATE_REPORTFORM_SKUREPORT_EXPORT: 99020511, // sku导出
    OPERATE_REPORTFORM_ORDER_REPORT_EXPORT: 99020512, // 订单导出

    OPERATE_DATAWATCH: 990606, // 数据概览
    OPERATE_DATAWATCH_GOODS: 99060601, // 商品数据概览
    OPERATE_DATAWATCH_ORDER: 99060602, // 订单概览

    OPERATE_FINANCE_MENU:990203,
    OPERATE_FINANCE_MENU_AUDIT:99020301,
    OPERATE_FINANCE_MENU_BILL:99020303,
    OPERATE_FINANCE_MENU_RECEIPT:99020302,
    OPERATE_FINANCE_BILL_VIEW:9902030301,
    OPERATE_FINANCE_BILL_ADD:9902030302,
    OPERATE_FINANCE_BILL_EXPORT:9902030303,
    OPERATE_FINANCE_BILL_EDIT:9902030304,
    OPERATE_FINANCE_BILL_DEL:9902030305,
    OPERATE_FINANCE_BILL_PAY:9902030306,
    OPERATE_FINANCE_BILL_RECEIPT:9902030307,
    OPERATE_FINANCE_BILL_REFUND:9902030308,
    OPERATE_FINANCE_BILL_CALL:9902030309,
    OPERATE_FINANCE_BILL_AUDIT_VIEW:9902030101,

    SETTING_ANNOUNCE:990307,
    SETTING_ANNOUNCE_LIST:9903070101,
    SETTING_ANNOUNCE_ADD:9903070102,
    SETTING_ANNOUNCE_EDIT:9903070103,
    SETTING_ANNOUNCE_DELETE:9903070104,

    SUPPLIER_AUTH: 9906, // 供应商管理
    SUPPLIER_AUTH_GOODS: 990601, // 商品管理
    SUPPLIER_AUTH_ORDER: 990602, // 交易管理
    SUPPLIER_AUTH_ADDRESS: 990603, // 物流管理
    SUPPLIER_AUTH_BID: 990604, // 我的投标

    SUPPLIER_AUTH_SHOPS: 990605, // 店铺管理
    SUPPLIER_AUTH_SHOPS_MSG: 99060501, // 店铺信息
    SUPPLIER_AUTH_SHOPS_CHANGE: 99060502, // 店铺装修
    SUPPLIER_AUTH_SHOPS_FANS: 99060503, // 店铺粉丝

    BUSINESS_AUTH: 9904, // 企业管理

    PURCHASE_AUTH: 9905, // 采购管理
    PURCHASE_AUTH_TENDER: 990501, // 我的招标

    // 资产管理("资产管理",990206L,9902L,Boolean.FALSE,null),
    ASSETS_MANAGE:990206,
    // 资产管理_录入("资产管理_录入",99020601L,990206L,Boolean.FALSE,null),
    ASSETS_MANAGE_ADD:99020601,
    // 资产管理_查看("资产管理_查看",99020602L,990206L,Boolean.FALSE,null),
    ASSETS_MANAGE_LIST:99020602,
    // 资产管理_编辑("资产管理_编辑",99020603L,990206L,Boolean.FALSE,null),
    ASSETS_MANAGE_EDIT:99020603,
    // 资产管理_领用及退库("资产管理_领用及退库",99020604L,990206L,Boolean.FALSE,null),
    ASSETS_MANAGE_RECEIVE_RETURN:99020604,
    // 资产管理_报修("资产管理_报修",99020605L,990206L,Boolean.FALSE,null),
    ASSETS_MANAGE_REPAIR:99020605,

    // 资产归属系统("资产归属系统",990308L,9903L,Boolean.FALSE,null),
    ASSETS_SYSTEM:990308,
    // 资产归属系统_列表("资产归属系统_列表",99030801L,990308L,Boolean.FALSE,null),
    ASSETS_SYSTEM_LIST:99030801,
    // 资产归属系统_新增("资产归属系统_新增",99030802L,990308L,Boolean.FALSE,null),
    ASSETS_SYSTEM_ADD:99030802,
    // 资产归属系统_编辑("资产归属系统_编辑",99030803L,990308L,Boolean.FALSE,null),
    ASSETS_SYSTEM_EDIT:99030803,
    // 资产归属系统_删除("资产归属系统_删除",99030804L,990308L,Boolean.FALSE,null)`;
    ASSETS_SYSTEM_DELETE:99030804,
});
