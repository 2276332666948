/* eslint-disable */
import { AJAX_TYPE } from '../config';

const prefix = 'activity';

const baseURL = '';

export default {
	// 活动商品列表
	[`${prefix}GoodsList`]: {
		url: baseURL + '/web/mgr/promotionPrice/queryList',
		type: AJAX_TYPE.POST
	},
	// 活动是否关停
	[`${prefix}SwitchStatus`]: {
		url: baseURL + '/web/mgr/promotion/getPromotionShopStatusForMall',
		type: AJAX_TYPE.GET
	},
	// 所有活动状态
	[`${prefix}AllStatus`]: {
		url: baseURL + '/web/crm/promotion/getListPromotionShopStatusForMall',
		type: AJAX_TYPE.GET
	},
	// 添加商品
	[`${prefix}GoodsAdd`]: {
		url: baseURL + '/web/mgr/promotionPrice/addItem',
		type: AJAX_TYPE.POST
	},
	// 商品移除
	[`${prefix}GoodsRemove`]: {
		url: baseURL + '/web/mgr/promotionPrice/removeItem',
		type: AJAX_TYPE.POST
	},
	// 获取活动商品sku列表
	[`${prefix}GoodsSkuList`]: {
		url: baseURL + '/web/mgr/promotionPrice/querySku',
		type: AJAX_TYPE.POST
	},
	// 修改员工价
	[`${prefix}GoodsPriceModify`]: {
		url: baseURL + '/web/mgr/promotionPrice/addSkuPrice',
		type: AJAX_TYPE.POST
	},
	// 批量设置员工价
	[`${prefix}GoodsPriceModityBatch`]: {
		url: baseURL + '/web/mgr/promotionPrice/addItemPrice',
		type: AJAX_TYPE.POST
	}
};
