/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// verifycode service

const prefix = 'verify';

export default {
    // 判断是否需要图片验证码
    [`${prefix}Captcha`]: {
        url: '/web/verify/captcha',
        type: AJAX_TYPE.GET
    },
    // 校验验证码
    [`${prefix}CheckValidateCode`]: {
        url: '/web/verify/checkValidateCode',
        type: AJAX_TYPE.GET
    },
    // validateCode
    [`${prefix}ValidateCode`]: {
        url: '/web/crm/verify/validateCode',
        type: AJAX_TYPE.GET
    },
}