/* eslint-disable */
/**
 * 投标管理
 */

import { AJAX_TYPE } from "../config";

const prefix = "warehouse";
const baseURL = "/web/mgr/tender/warehouse";

export default {
  // 入库邀请列表
  [`${prefix}InvitationList`]: {
    url: baseURL + "/getInvitationList",
    type: AJAX_TYPE.POST
  },

  // 状态统计信息
  [`${prefix}Count`]: {
    url: baseURL + "/count",
    type: AJAX_TYPE.POST
  },

  // 忽略邀请
  [`${prefix}Ignore`]: {
    url: baseURL + "/ignore",
    type: AJAX_TYPE.POST
  },

  // 申请详情
  [`${prefix}Detail`]: {
    url: baseURL + "/detail",
    type: AJAX_TYPE.GET
  },

  // 申请入库
  [`${prefix}Apply`]: {
    url: baseURL + "/apply",
    type: AJAX_TYPE.POST
  }
};
