/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// Dev.ToolManager

const prefix = 'enterprise';

export default {
    // 删除草稿
    [`${prefix}IsComplete`]: {
        url: '/web/mgr/enterprise/enterpriseComplete',
        type: AJAX_TYPE.GET
    },
}