/* eslint-disable */

import md5 from 'js-md5'
const sha256 = require("js-sha256").sha256; //引入sha256库
const isForUC = false; // 是UC登录
const appId = '2ef4c027eee911e98a1cd22e1d4c10ad'; // uc appid
const sourceType = 2; // 1为C端  2为B端
function getLocalHost () {
    if (window.location.port == "3000") { // 本地联调
        return '';
    } else {
        const host = window.location.host == 'oms.st.idf10.com.cn' ? 'http://i.st15.idf10.com.cn' : 'https://i.an2.idf10.cn'
        return host;
    }
}


// ------------------ 加签 start ------------------------------

function sort_ASCII(obj){
    var arr = [];
    var num = 0;
    for (let i in obj) {
        arr[num] = i;
        num++;
    }
    var sortArr = arr.sort();
    var sortObj = {};
    for (let i in sortArr) {
        sortObj[sortArr[i]] = obj[sortArr[i]];
    }
    return sortObj;
}
 
function obj2qs(obj){
    if (!obj && !Object.keys(obj).length) {
        return "";
    } else {
        var arr = [];
        for (let key in obj) {
            obj[key] && arr.push(key + "=" + obj[key]);
        }
        return arr.join("&");
    }
}
 
//生成随机整数
function RandomTwo(){
    const math = parseInt(Math.random() * 100);
    if (math > 9) {
        return math;
    } else {
        return math + 10;
    }
}
 
//转换base64加密密码
function toBase64(str) {
    return window.btoa(encodeURIComponent(escape(str)));
}
 
//md5算法加签样例
function getSign(data) {
    let noce = RandomTwo();
    let { password } = data;
    if (password) {
        data.password = toBase64(password);
    }
    var loginForm = {
        ...data,
        "noce":noce,
        "alg": "MD5",
        "timestamp": parseInt(new Date().getTime() / 1000)
    }
    let forsigin = { ...loginForm };
    forsigin = sort_ASCII(forsigin);
    forsigin = obj2qs({ ...forsigin, key: "secret-haha" });
    const sign = md5(forsigin);
    return { ...loginForm, sign };
}
 
//sha256算法加签样例
function getSignSha256(data) {
    let noce = RandomTwo();
    let { password } = data;
    if (password) {
        data.password = toBase64(password);
    }
    var loginForm = {
        ...data,
        "noce":noce,
        "alg": "SHA256",
        "timestamp": parseInt(new Date().getTime() / 1000)
    }
    let forsigin = { ...loginForm };
    forsigin = sort_ASCII(forsigin);
    forsigin = obj2qs({ ...forsigin, key: "secret-haha" });
    const sign = sha256(forsigin);
    return { ...loginForm, sign };
}

function getImgCodeImage (isLogin) {
    var host = '';
      if (window.location.port == "3000") {
        host = 'http://10.59.75.42:8286'
      } else {
        host = window.location.host == 'oms.st.idf10.com.cn' ? 'http://i.st15.idf10.com.cn' : 'https://i.an2.idf10.cn'
      }
      return isLogin?
      `${host}/modules/api/account/common/imgcode?imgCodeType=2&random=${Math.random()}`
      :
      `${host}/modules/api/account/common/imgcode?random=${Math.random()}`
}

// ------------------ 加签 end ------------------------------

export default {
    getSignSha256,
    getSign,
    getLocalHost,
    isForUC,
    appId,
    getImgCodeImage,
    toBase64,
    sourceType
}