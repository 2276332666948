/* eslint-disable */
import { AJAX_TYPE } from '../config';

const prefix = 'shop';

const baseURL = ''
// process.env.NODE_ENV == 'development' ? '' : 'http://oms-mall.st1.idf10.com.cn';

export default {
	// 店铺详情
	[`${prefix}GetByCurrentuser`]: {
		url: '/web/mgr/shop/getByCurrentuser',
		type: AJAX_TYPE.GET
	},
	// 主营类目
	[`${prefix}CategoryQueryTop`]: {
		url: baseURL + '/web/mgr/category/queryTop',
		type: AJAX_TYPE.GET
	},
	// 修改店铺
	[`${prefix}Save`]: {
		url: '/web/mgr/shop/save',
		type: AJAX_TYPE.POST
	},
	// 店铺详情2
	[`${prefix}GetShopInfo`]: {
		url: '/web/mgr/shop/getShopInfo/:code',
		type: AJAX_TYPE.GET
	},
	// 查询店铺分类
	[`${prefix}QueryShopCategorys`]: {
		url: '/web/mgr/shopCategory/queryShopCategorys',
		type: AJAX_TYPE.POST
	},
	// 创建店铺分类
	[`${prefix}SaveShopCategory`]: {
		url: '/web/mgr/shopCategory/saveShopCategory',
		type: AJAX_TYPE.POST
	},
	// 分类排序
	[`${prefix}SortShopCategory`]: {
		url: '/web/mgr/shopCategory/sortShopCategory',
		type: AJAX_TYPE.POST
	},
	// 添加商品到分类
	[`${prefix}ShopCategoryAddGoods`]: {
		url: '/web/mgr/shopCategory/shopCategoryAddGoods',
		type: AJAX_TYPE.POST
	},
	// 查看分类商品
	[`${prefix}QueryListForShop`]: {
		url: '/web/mgr/shopCategory/queryListForShop',
		type: AJAX_TYPE.POST
	},
	// 删除分类中商品
	[`${prefix}ShopCategoryDeleteGoods`]: {
		url: '/web/mgr/shopCategory/shopCategoryDeleteGoods',
		type: AJAX_TYPE.POST
	},
	// 查询
	[`${prefix}ShopCategoryDeleteGoods`]: {
		url: '/web/mgr/shopCategory/queryListForShop',
		type: AJAX_TYPE.POST
	},


	// 查询店铺姓名是否存在
	[`${prefix}ValidateName`]: {
		url: '/web/mgr/shop/validateName',
		type: AJAX_TYPE.POST
	},

	// 查询店铺粉丝统计
	[`${prefix}QueryShopFansCount`]: {
		url: '/web/mgr/shop/queryShopFansCount',
		type: AJAX_TYPE.POST
	},
	// 查询店铺粉丝列表
	[`${prefix}QueryShopFansList`]: {
		url: '/web/mgr/shop/queryShopFansList',
		type: AJAX_TYPE.POST
	},
	// 获取开户总行
	[`${prefix}GetBankList`]: {
		url: '/api/payPlatform/v1/bankCard/selectBank',
		type: AJAX_TYPE.POST
	},
};
