/* eslint-disable */
import area from './apilist/area';
import bill from './apilist/bill';
import billpay from './apilist/billpay';
import billrefund from './apilist/billrefund';
import common from './apilist/common';
import contract from './apilist/contract';
import customer from './apilist/customer';
import decision from './apilist/decision';
import dev from './apilist/dev';
import floor from './apilist/floor';
import flowTemplate from './apilist/flowTemplate';
import intention from './apilist/intention';
import job from './apilist/job';
import maintain from './apilist/maintain';
import ordercomplain from './apilist/ordercomplain';
import project from './apilist/project';
import statementcontract from './apilist/statementcontract';
import statementproject from './apilist/statementproject';
import statementzone from './apilist/statementzone';
import sysconfig from './apilist/sysconfig';
import template from './apilist/template';
import companyinitCompany from './apilist/companyinitCompany';
import department from './apilist/department';
import functionAuth from './apilist/function';
import message from './apilist/message';
import myWork from './apilist/myWork';
import projectstats from './apilist/projectstats';
import role from './apilist/role';
import supplier from './apilist/supplier';
import task from './apilist/task';
import user from './apilist/user';
import verify from './apilist/verify';
import zone from './apilist/zone';
import zonedecoration from './apilist/zonedecoration';
import draft from './apilist/draft';
import upload from './apilist/upload';
import reportform from './apilist/reportform';
import announce from './apilist/announce';
import help from './apilist/help';
import category from './apilist/category';
import item from './apilist/item';
import trans from './apilist/trans';
import order from './apilist/order';
import shop from './apilist/shop';
import comment from './apilist/comment';
import delivery_template from './apilist/delivery_template';
import afterSales from './apilist/afterSales';
import shop_category from './apilist/shop_category';
import address from './apilist/address';
import activity from './apilist/activity';
import bid from './apilist/bid';
import overview from './apilist/overview';
import newHelp from './apilist/help_new';
import deposit from './apilist/deposit';
import warehouse from './apilist/warehouse'
import refund from './apilist/refund'
import express from './apilist/express'
import enterprise from './apilist/enterprise'
import quote from './apilist/quote';

import assets from './apilist/assets';

import userUtils from '@/utils/userUrl';
import elecSign from './apilist/elecSign'

const mgrReg = '/web/mgr'
const mallReg = '/web/mall'
const crmReg = '/web/crm'
const mallMsg = '/web/message';
const mallArea = '/web/area';
const mallDraft = '/web/draft'

const baseMallURL = process.env.NODE_ENV == "development" ? '' : userUtils.mallUrlForHttp
const baseCrmURL = process.env.NODE_ENV == "development" ? '' : userUtils.crmUrlForHttp
const config = {
	...assets,
	...afterSales,
	...area,
	...bill,
	...billpay,
	...billrefund,
	...common,
	...contract,
	...customer,
	...decision,
	...dev,
	...floor,
	...flowTemplate,
	...intention,
	...job,
	...maintain,
	...ordercomplain,
	...project,
	...statementcontract,
	...statementproject,
	...statementzone,
	...sysconfig,
	...template,
	...companyinitCompany,
	...department,
	...functionAuth,
	...message,
	...myWork,
	...projectstats,
	...role,
	...supplier,
	...task,
	...user,
	...verify,
	...zone,
	...zonedecoration,
	...draft,
	...upload,
	...reportform,
	...announce,
	...help,
	...category,
	...item,
	...trans,
	...order,
	...shop,
	...comment,
	...delivery_template,
	...shop_category,
	...address,
	...activity,
	...bid,
	...overview,
	...newHelp,
	...assets,
	...deposit,
	...warehouse,
	...refund,
	...elecSign,
	...express,
	...enterprise,
	...quote
}
Object.keys(config).forEach(key => {
	if (config[key].url.indexOf(mgrReg) !== -1 
		|| config[key].url.indexOf(mallReg) !== -1
		|| config[key].url.indexOf(mallMsg) !== -1
		|| config[key].url.indexOf(mallArea) !== -1
		|| config[key].url.indexOf(mallDraft) !== -1
		) { // 原来商城服务
		if (!config[key].url.startsWith('http')) {
			config[key].url = '/api/supply' + config[key].url
		}
	}
	if (config[key].url.indexOf(mgrReg) !== -1 || config[key].url.indexOf(mallReg) !== -1) { // 走mall
		if (!config[key].url.startsWith('http')) {
			config[key].url = baseMallURL + config[key].url
		}
	} else if (config[key].url.indexOf(crmReg) !== -1) { // 走crm
		if (!config[key].url.startsWith('http')) {
			config[key].url = baseCrmURL + config[key].url
		}
	}
	
})

export const API = Object.freeze(config);
