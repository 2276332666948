/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';


const prefix = 'order';

export default {
    // 发货列表
    [`${prefix}DeliveryList`]: {
        url: '/web/mgr/order/delivery/list',
        type: AJAX_TYPE.POST
    }, 
    //发货详情
    [`${prefix}DeliveryDetail`]: {
        url: '/web/mgr/order/delivery/detail/:mainCode',
        type: AJAX_TYPE.GET
    },
    //卖家备注 
    [`${prefix}SellerRemark`]: {
        url: '/web/mgr/order/sellerRemark',
        type: AJAX_TYPE.POST
    }, 
    //查询地址
    [`${prefix}DefaultAddress`]: {
        url: '/web/mgr/address/queryAddress',
        type: AJAX_TYPE.POST
    }, 
    //保存地址
    [`${prefix}SaveAddress`]: {
        url: '/web/mgr/order/saveAddress',
        type: AJAX_TYPE.POST
    },
    //物流公司
    [`${prefix}Logistics`]: {
        url: '/web/mgr/logistics/init',
        type: AJAX_TYPE.GET
    },
    //发货 
    [`${prefix}Deliver`]: {
        url: '/web/mgr/order/delivery/send',
        type: AJAX_TYPE.POST
    },
    //导出发货列表
    [`${prefix}ExportDeliveryList`]: {
        url: '/web/mgr/order/exportDeliveryList',
        type: AJAX_TYPE.POST
    },
	// 列表查询条件
	[`${prefix}Condition`]: {
		url: '/web/mgr/order/condition',
		type: AJAX_TYPE.GET
	},
	// 订单列表
	[`${prefix}List`]: {
		url: '/web/mgr/order/list',
		type: AJAX_TYPE.POST
    },
    // 订单详情
    [`${prefix}Detail`]: {
        url: '/web/mgr/order/detail/:orderCode',
        type: AJAX_TYPE.GET
    },
    // 货品签收单
    [`${prefix}Detail4Print`]: {
        url: '/web/mgr/order/detail4Print/:orderCode',
        type: AJAX_TYPE.GET
    },
    // 修改sku价格
    [`${prefix}PriceModify`]: {
        url: '/web/mgr/order/editPrice',
        type: AJAX_TYPE.POST
    },
    // 关闭交易
    [`${prefix}Close`]: {
        url: '/web/mgr/order/cancel',
        type: AJAX_TYPE.POST
    },
    // 获取凭证
    [`${prefix}CertificationList`]: {
        url: '/web/mgr/pay/PaymentVoucher/:orderCode',
        type: AJAX_TYPE.GET
    },
    // 凭证审核
    [`${prefix}CertificationAudit`]: {
        url: '/web/mgr/pay/PaymentVoucher/audit',
        type: AJAX_TYPE.POST
    },
    // 导出订单
    [`${prefix}Export`]: {
        url: '/web/mgr/order/export',
        type: AJAX_TYPE.POST
    },
    // 开发票
    [`${prefix}GenerateInvoice`]: {
        url: '/web/mgr/invoice/uploadInvoice',
        type: AJAX_TYPE.POST
    },
    //订单数据概览-实时数据
    [`${prefix}Overview`]: {
        url: '/web/mgr/order/overview',
        type: AJAX_TYPE.GET
    }, 
    //订单数据概览-图表部分
    [`${prefix}OverviewChart`]: {
        url: '/web/mgr/order/overview/chart',
        type: AJAX_TYPE.GET
    }
}