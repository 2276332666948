/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

export default {
    // 报价管理-列表
    [`QuoteList`]: {
        url: '/web/mgr/quote/getList',
        type: AJAX_TYPE.POST
    },
    [`QuoteDetail`]: {
        url: '/web/mgr/quote/detail',
        type: AJAX_TYPE.GET
    },
    [`QuoteSave`]: {
        url: '/web/mgr/quote/save',
        type: AJAX_TYPE.POST
    },
    [`QuoteRevocation`]: {
        url: '/web/mgr/quote/revocation',
        type: AJAX_TYPE.POST
    },
    [`QuoteDownQuoteInfoExcel`]: {
        url: '/web/mall/findsource/excel/downProductQuoteInfoExcelTemplate',
        type: AJAX_TYPE.GET
    },
    [`QuoteUploadQuoteInfoExcel`]: {
        url: '/web/mall/findsource/excel/uploadProductQuoteInfoExcelTemplate',
        type: AJAX_TYPE.POST
    },
}