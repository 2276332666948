/* eslint-disable */
import axios from "axios";
import userUtil from "@/utils/user";
import { _typeof } from "@/utils/index";
import { refreshRoute } from "@/route/config";
import storage from "@/utils/storage";
import {
  showLoading as loading,
  hideLoading as loadingEnd,
  showErrorMessage,
  showSuccessMessage
} from "@/utils/globalMessageReminder";
import { API } from "./api";
import { AJAX_TYPE } from "./config";
import UcSign from "@/utils/UcSign";
const { getSign, getSignSha256, sourceType } = UcSign;
let canTips = true;
axios.defaults.withCredentials = true; // 允许携带cookie
export default function http(config) {
  if (userUtil.isLogin()) {
    // addUserToken(config);
    return ajax(config);
  } else {
    // logout
    userUtil.logout();
  }
}

export function getUserCompanyDisableFlag() {
  const config = {
    api: API.userUserCompanyDisableFlag,
  };
  return ajax(config).then(res => {
    
    return res;
  });
}

function addUserToken(config) {
  const user = userUtil.get();
  config.headers = {
    token: user.token
  };
}

function goTo403() {
  window.location.href = "/#/403";
}

export function login(data) {
  const config = {
    api: API.userUnifiedLogin,
    // api: API.userLoginForWeb,
    // successTip: '登录成功！',
    data
  };
  // return
  return ajax(config).then(res => {
    // loginForOms(data)
    if (res.success) {
      userUtil.login(res.data);
      // refreshRoute();
    }
    return res;
  });
}

export function getValidFunction() {
  const config = {
    api: API.functionValidFunction,
    // api: API.userLoginForWeb,
    data: {
      account: userUtil.get() && userUtil.get().mobile
    }
  };
  // return
  return ajax(config).then(res => {
    if (res.success && res.data) {
      if (
        (res.data.functionList && res.data.functionList.length > 0) ||
        (res.data.userPrinciples && res.data.userPrinciples.length > 0)
      ) {
        let userInfo = userUtil.get();
        if (res.data.functionList && res.data.functionList.length > 0) {
          userInfo.functionList = res.data.functionList;
        }
        if (res.data.userPrinciples && res.data.userPrinciples.length > 0) {
          userInfo.userPrinciples = res.data.userPrinciples;
        }
        userUtil.login(userInfo);
        setTimeout(() => {
          refreshRoute();
        }, 500);
        // window.location.reload();
      }
    }
    return res;
  });
}

export function loginForOms(data) {
  const config = {
    api: API.userLoginForWeb,
    // api: API.userLoginForWeb,
    successTip: "登录成功！",
    data
  };
  // return
  return ajax(config).then(res => {
    if (res.success) {
      userUtil.addUserInfo(res.data);
      refreshRoute();
    }
    return res;
  });
}

// =================== loginForUc ===============================
export function loginForUc(data) {
  var signData = {
    loginNo: data.loginNo,
    password: data.password
  };
  if (data && data.picCode) {
    signData.imgAuthCode = data.picCode.trim();
  }
  signData.device = 1;
  signData.sourceType = sourceType;
  const config = {
    api: API.userLoginForUcWeb,
    Accept: "application/json",
    contentType: "application/json;charset=utf-8",
    xhrFields: { withCredentials: true },
    headers: {
      appId: "2ef4c027eee911e98a1cd22e1d4c10ad"
    },
    // successTip: '登录成功！',
    data: getSignSha256(signData)
  };
  return ajax(config).then(res => {
    if (res.success) {
      userUtil.login(res.data);
      refreshRoute();
    }
    return res;
  });
}

// =================== loginForUc ===============================

const DEFAULT_API_TYPE = AJAX_TYPE.GET;

export function ajax(config) {
  const ajaxConfig = getAjaxConfig(config);
  const showLoading = !!config.showLoading;
  ajaxConfig.crossDomain = true;
  if (!ajaxConfig.headers) {
    ajaxConfig.headers = {}
  } 

  ajaxConfig.headers["app-deviceID"] = new PaWebJs().get()
  
  return new Promise((resolve, reject) => {
    axios(ajaxConfig)
      .then(res => {
        resolve(res);
      })
      .catch(err => {});
  });
}


export function uploadRequest(byteArray, value) {
  axios.defaults.crossDomain = true;
         axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
        axios.defaults.headers.common["Authorization"] = ""; // 设置请求头为 Authorization

      var fileName = value.file.name;
      var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);


      return axios({
          method: 'put',
          url: "/api/open/fileUpload" + (fileExtension ? ("?suffix=" + fileExtension) : ''),
          data: new Blob([byteArray]),
          headers: {
              'Content-Type': 'multipart/form-data', // 关键
          },
      }).then((res) => {
          // value.onSuccess({ file: res.data });
          return res;
      }).catch(err => {
          value.onError(err);
      });
}

// http请求拦截器

export function formRequest(formData) {
  const fromData = new FormData();
  const user = userUtil.get();
  fromData.append("file", formData.file);
  const config = {
    // onUploadProgress: progressEvent => {
    //     formData.value.onProgress(progressEvent.loaded / progressEvent.total * 100 | 0);
    // },
    headers: {
      // token: user.token,
      "Content-Type": "multipart/form-data"
    }
  };
  return axios
    .post(formData.action, fromData, config)
    .then(res => {
      return res;
      // value.onSuccess(res);
    })
    .catch(err => {
      formData.value.onError && formData.value.onError(err);
    });
}
export function quoteFormRequest(formData,code) {
  const fromData = new FormData();
  fromData.append("file", formData.file);
  fromData.append("inquiryCode", code);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };
  return axios
    .post(formData.action, fromData, config)
    .then(res => {
      return res;
    })
    .catch(err => {
      formData.value.onError && formData.value.onError(err);
    });
}

axios.interceptors.request.use(
  config => {
    if (config.loading) loading();
    return config;
  },
  error => {
    //请求错误处理

    Promise.reject(error);
  }
);

// http响应拦截器
axios.interceptors.response.use(
  data => {
    if (/\/\/upload./.test(data.request.responseURL)) {
      return data.data;
    }
    if (data.config.loading) loadingEnd();
    // console.log(data, 'data')
    // if (data.data.errorCode === '10041' || data.data.errorCode === '10038' || data.data.errorCode === '10029') {
    //     return Promise.reject();
    // } else
    if (data.data.code === "200001") {
      console.log("  data.request.responseURL--->", data.request.responseURL);
      console.log("  200001 login error ",data.data);
        showErrorMessage(data.data.msg);
        // 先清localStorage
        userUtil.logout();
        return Promise.reject();
    } else if (data.data.code === "300001") {
      console.log("  data.request.responseURL--->", data.request.responseURL);

      console.log(" 300001  login error ",data.data);

      goTo403();
      showErrorMessage(data.data.msg);
      return Promise.reject();
    } else if (data.data.code === "2000013" || data.data.code === "9999") {
      showErrorMessage(data.data.msg);
      return Promise.reject();
    } else if (data.data.code === "300014" /*|| data.data.code === "400014"*/) {
      console.log("  data.request.responseURL--->", data.request.responseURL);

      console.log("  300014 login error ",data.data);

      showErrorMessage(data.data.msg);
      userUtil.logout();
      return Promise.reject();
    } else if (data.data.code === "500001") {
      if (canTips) {
        showErrorMessage(data.data.msg);
        canTips = false;
        setTimeout(() => {
          canTips = true;
        }, 10000);
      }
    } else if (_typeof(data.data) === "Object") {
      if (data.data.success) {
        if (data.config.successTip) {
          showSuccessMessage(data.config.successTip);
        }
      } else {
        var hasDone = false;
        if (data.config.httpFail) {
          hasDone = data.config.httpFail(data.data.code, data.data.msg);
        }

        if (!hasDone && !data.config.noMessage) {
          showErrorMessage(data.data.msg);
        }
      }
    }
    return data.data;
  },
  error => {
    loadingEnd();
    if (error.response) {
      if (error.response.config.loading) loadingEnd();
      switch (error.response.status) {
        case 500:
          showErrorMessage(error.response.data.msg || "服务器报错");
          break;
        default:
          showErrorMessage(error.response.data.msg || "服务器报错");
      }
      return Promise.reject(error.response.data); // 返回接口返回的错误信息
    }
    return Promise.reject(error);
  }
);
/**
 *
 * @example
 * getAjaxConfig({
        api: api.*,
        path: {},
        data: {},
        showLoading: true
    })
 */
function getAjaxConfig(config = {}) {
  const { api, path, data, headers, responseType } = config;
  const type = api.type || DEFAULT_API_TYPE;

  // let url = api.url.indexOf('/web/crm') > -1 ? (userUtil.crmUrlForHttp + api.url) : api.url;
  let url = api.url;
  let xhrFields = api.xhrFields || null;
  if (path) {
    Object.entries(path).forEach(([key, value]) => {
      url = url.replace(":" + key, value);
    });
  }

  const ajaxConfig = {
    url,
    method: type,
    responseType
  };

  if (xhrFields) {
    ajaxConfig.xhrFields = xhrFields;
  }

  if (data) {
    if (type === AJAX_TYPE.GET) {
      ajaxConfig.params = data;
    } else {
      ajaxConfig.data = data;
    }
  }

  if (headers) {
    ajaxConfig.headers = headers;
  }
  if (config.showLoading) {
    ajaxConfig.loading = config.showLoading;
  }
  if (config.successTip) {
    ajaxConfig.successTip = config.successTip;
  }
  if (config.httpFail) {
    ajaxConfig.httpFail = config.httpFail;
  }
  if (config.noMessage) {
    ajaxConfig.noMessage = config.noMessage;
  }
  return ajaxConfig;
}
