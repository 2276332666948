/* eslint-disable */
import {
    AJAX_TYPE
} from '../config';

// 上传、下载

const prefix = 'upload';

export default {
    // 上传 这个接口是在我们自己的服务器上 通过 http 中的 formRquest 请求 封装了表单请求 
    //上传demo cmpt/operate/ImageUploadBiz.jsx
    [`${prefix}Add`]: {
        url: '/api/open/fileUpload',
        type: AJAX_TYPE.POST
    },

    
    //http://10.59.72.21:9003/swagger-ui.html#/ 
    //文件服务器说明文档 http://pms.ipo.com/pages/viewpage.action?pageId=129833880
    //上传/下载服务 api 文档（服务端看的） http://pms.ipo.com/pages/viewpage.action?pageId=127177021
    //文件流不通过ajx domain+url+key(上传返回) 获取 
    //下载 文件流 http://30.76.226.204:8288/node/download/view/public/111306017283158589  
    [`${prefix}download`]: {
        url: '/node/download/view/public',
        type: AJAX_TYPE.GET
    },  
    //预览 文件流  http://30.76.226.204:8288/node/download/view/public/show/111306017283158589
    [`${prefix}show`]: {
        url: '/node/download/view/public',
        type: AJAX_TYPE.GET
    },       
}