/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import '@/developHelper';
// import "antd/dist/antd.less";
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
// import '@/style/index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/zh-cn';



moment.locale('zh-cn');

// import '@/utils/log'

ReactDOM.render(<ConfigProvider locale={zh_CN}><App /></ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register(); //热更新
